<template>
  <div class="" style="width: 100%">
   

    <!-- ------------------------------------------------ -->
    <div class="row ">
      <div class="col-md-6">
         <div class="box profile-card-2">
      <div class="row">
        <div class="col-6" style="text-align: left">
          <span class="start_up_label">FULL NAME</span>
        </div>
        <div
          class="col-6"
          style="text-align: right"
          data-bs-toggle="modal"
          data-bs-target="#editStartUpInfo2"
        >
          <span class="start_up_edit" style="display: none">Edit</span>
        </div>
      </div>
      

      <div class="row">
        <div class="col-12">
          <div class="start_up_data">{{ user.name }}</div>
        </div>
      </div>

      <div class="start_up_line"></div>
      <div class="row" style="padding-top: 30px; min-height: 143px">
        <div
          class="col-sm-12"
          style="text-align: left; margin-top: -10px; padding-top: 10px"
        >
          <span class="start_up_label">email address</span>

          <div class="start_up_data">
            {{ user.email}}
          </div>
        </div>
      </div>

      <div class="start_up_line"></div>

      <div class="row" style="padding-top: 30px; padding-bottom: 20px">
        <div
          class="col-sm-6"
          style="text-align: left; margin-top: -10px; padding-top: 10px"
        >
          <span class="start_up_label">phone number</span>

          <div class="start_up_data">
            {{ `${user.phone_number_code || ""}  ${user.phone_number || ""} ` }}
          </div>
        </div>
        <div
          class="col-sm-6"
          style="
            text-align: left;
            border-left: 1px solid #e4e5e7;
            margin-top: -10px;
            margin-left: -20px;
            padding-left: 20px;
            padding-top: 10px;
          "
        >
          <span class="start_up_label">location</span>

          <div class="start_up_data">{{  user.timezone  }}</div>
        </div>
      </div>

      <div class="start_up_line"></div>
      <div class="row" style="padding-top: 30px; min-height: 143px">
        <div
          class="col-sm-12"
          style="text-align: left; margin-top: -10px; padding-top: 10px"
        >
          <span class="start_up_label">PERSONAL BIO</span>

          <div class="start_up_data">
            {{ user.bio }}
          </div>
        </div>
      </div>
    </div>
      </div>

      <div class="col-md-6">
        <div class="box profile-card-2" style="min-height: 560px;">
          <div class="layer" style="border: none">
            <span class="title">PROFILE PICTURE </span>
            <span @click="openAvaterUpload()" class="float-to-the-right mouse">
              Change 

              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="secSpinner"
              />
            </span>
            <input
              accept="image/*"
              ref="avatarInput"
              @change="onFilePicked"
              type="file"
              id="uploadProfilePic"
            />
          </div>

          <div class="layer-two" style="margin-top: 16%;">
            <div class="avatar">
              <img
                v-show="profile_spinner"
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
              />
              <img
                class="circle-avatar"
                :src="$imageUrl + user.profile_picture || imageAvatar"
              />
            </div>
          </div>
        </div>

        <div style="width:100%;border:0px solid red;margin-top:15px;background-color: #fff;;">
             <DeactivateAccount/>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { editDevImage } from "@/api/client";

import DeactivateAccount from "./../deactivateAccount.vue";

//

//

//

//

export default {
  props: {},
  components: {
    DeactivateAccount,
  },

  data: () => ({
    image: null,
    secSpinner: false,
    profile_spinner: false,
    foundersData: [],
    imageAvatar: "/img/onboarding/profile-avatar.svg",

    form_errors: {
      stage_category_id: [],
      selectedBackedBy: [],
      funding_amount: [],
      backed_by: [],
    },

    form: {
      stage_category_id: 0,
      stage_id: 0,
      backed_by: "",
      selectedBackedBy: "nil",
      compensation_id: 0,
      funding_amount: "",
    },
    //
  }),

  created() {},
  mounted() {},
  methods: {
    ...mapActions("userManagement", ["updateUserDetails"]),

    openAvaterUpload() {
      this.$refs.avatarInput.click();
    },
    onFilePicked(event) {
    
      this.secSpinner = true;
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      event = null;
      this.secSpinner = false;
      this.handleProfile();
      
    },
    handleProfile() {
      this.profile_spinner = true;
      let dataForm = new FormData();
      dataForm.append("profile_picture", this.image);
      editDevImage(dataForm)
        .then((response) => {
          if (response.data.status == 1) {
            // this.successMessage = true;
            // this.successText = "Profile Image Updated";
            // Update Sidebar Photo
            this.$emit("fetchDetails");
            this.updateUserDetails(response.data[0]);
            this.profile_spinner = false;
          } else if (response.data.status == 0) {
            this.errorMessage = true;
            this.errorText = response.data.msg;
          }
        })
        .catch((error) => {
          this.profile_spinner = false;
          this.$developConsole(error, "error");
        });
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", ["clientProject"]),

    user() {
      return this.currentUser;
    },

    project() {
      return this.clientProject;
    },
  },
};
</script>
<style scoped>
.lightBorder {
  border: 1px solid #eee;
}
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* primary/700 (text) */

  color: #1b1e22;
  padding-bottom: 10px;
}

.profile-card-2 {
  text-align: left;
  background: #ffffff;

  border-bottom: 1px solid #eee;
  padding: 20px;
}
.start_up_line {
  border: 0.5px solid #e4e5e7;
}

.start_up_data {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-top: 30px;
  padding-bottom: 20px;

  color: #1b1e22;
}
.start_up_edit {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;

  text-align: right;
  cursor: pointer;
  color: #0781f2;
}
.start_up_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  text-transform: uppercase;

  color: #979da5;
}

.investor-padding {
  padding-right: 0px;
  margin-top: 10px;
}
.startup-padding {
  padding-left: 20px;
  margin-top: 10px;
}
.card-container {
  margin-left: 37px;
  margin-top: 30px;
  padding-right: 15px;
}

.card-item-container {
  width: 100%;
  padding-left: 5px;
  margin-top: -70px;
  border: 0px solid red;
}
.profile-cover {
  display: flex;
  width: 100%;
  padding: 40px;
  padding-top: 0px;
}
.profile-cover .item {
  background: #ffffff;
  width: 100%;
  margin: 10px;
  padding: 10px;
  margin-top: 70px;
  border-bottom: 1px solid #eee;
}
.profile-cover .item .layer {
  width: 90%;
  margin: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
}
.profile-cover .item .layer span {
  float: left;
  text-align: left;
  width: 50%;
}
span.title {
  color: #979da5;
  font-size: 12px !important;
  padding-bottom: 30px;
}
.float-to-the-right {
  float: right !important;
  text-align: right !important;
  color: #0781f2;
}
.grid-display {
  display: grid;
}
.profile-cover .item .layer-two {
  width: 90%;
  margin: 24px;
  padding-bottom: 24px;
}
.item-two {
}
.layer-two .avatar {
  width: 40%;
  width: 286px;
  height: 286px;
  vertical-align: left;
  border: none;
  background: #54bdd4;
  border-radius: 50%;
  margin: 0 auto;
}
.circle-avatar {
  width: 40%;
  width: 286px;
  height: 286px;
  vertical-align: left;
  border: none;
  background: #076276;
  border-radius: 50%;
  border:0px solid red;
  margin: 0 auto;
}
#uploadProfilePic {
  display: none;
}
.mouse {
  cursor: pointer;
}
</style>
