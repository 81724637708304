var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("profileNavbar", {
        attrs: { project_name: _vm.project.project_name },
      }),
      !_vm.hasProject
        ? _c(
            "div",
            {
              staticStyle: {
                "background-color": "#f7f7f8",
                display: "flex",
                "padding-top": "50px",
                "justify-content": "center",
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm.hasProject
        ? _c(
            "div",
            {
              staticClass: "tab3 tab-container",
              staticStyle: {
                "background-color": "#f7f7f8",
                display: "flex",
                "padding-top": "50px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card-container",
                  staticStyle: { width: "100%" },
                },
                [
                  _c("div", { staticClass: "row card-item-container" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 startup-padding" },
                      [
                        _c(
                          "productNameCard",
                          _vm._b(
                            {},
                            "productNameCard",
                            {
                              project_name: this.project.project_name,
                              overview: this.project.overview,
                              kick_off: this.project.kick_off,
                              project_id: this.project.id,
                            },
                            false
                          )
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 investor-padding" },
                      [
                        _c("industryCard", {
                          attrs: {
                            form: _vm.industryFields,
                            project_id: this.project.id,
                          },
                        }),
                        _c("br"),
                        _c("platformCard", {
                          attrs: {
                            form: _vm.platformFields,
                            project_id: this.project.id,
                          },
                        }),
                        _c("br"),
                        _c("techStackCard", {
                          attrs: {
                            form: _vm.stackFields,
                            project_id: this.project.id,
                            startup_id: _vm.project.startup.id,
                          },
                        }),
                        _c("br"),
                        _c("lookingForCard", {
                          attrs: {
                            form: _vm.lookingForFields,
                            project_id: this.project.id,
                            startup_id: _vm.project.startup.id,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.hasProject
        ? _c(
            "div",
            {
              staticClass: "tab2 tab-container",
              staticStyle: { "background-color": "#f7f7f8", display: "none" },
            },
            [
              _c(
                "founderCard",
                _vm._b(
                  {},
                  "founderCard",
                  {
                    client_id: this.project.client_id,
                    project_id: this.project.id,
                    founders: JSON.parse(this.foundersData || []),
                  },
                  false
                )
              ),
              _c("div", { staticClass: "card-container" }, [
                _c("div", { staticClass: "row card-item-container" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 startup-padding" },
                    [
                      _c(
                        "startupNameCard",
                        _vm._b(
                          {},
                          "startupNameCard",
                          {
                            startup: this.project.hasOwnProperty("startup")
                              ? this.project.startup
                              : [],
                            user_type: this.user.user_type,
                            project_id: this.project.id,
                          },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 investor-padding" },
                    [
                      _c("investorCard", {
                        attrs: {
                          client_id: _vm.project.client_id,
                          startup_id: _vm.project.startup.id,
                          project_id: _vm.project.id,
                          stage: _vm.project.startup.stage.label,
                          backed_by: _vm.project.startup.backed_by,
                          price: _vm.project.price,
                          form_errors: _vm.form_errors,
                          form: _vm.form,
                        },
                      }),
                      _c("preferedCard", {
                        attrs: {
                          client_id: _vm.project.client_id,
                          startup_id: _vm.project.startup.id,
                          project_id: _vm.project.id,
                          compensation: _vm.project.compensation_id,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.hasProject
        ? _c(
            "div",
            {
              staticClass: "profile-cover tab1 tab-container",
              staticStyle: { padding: "0px", display: "none" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card-container",
                  staticStyle: {
                    width: "100%",
                    "margin-left": "0px",
                    "padding-left": "40px",
                    "margin-top": "10px",
                  },
                },
                [_c("profile")],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "60%" } }, [
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: {
            padding: "20px",
            "background-color": "#e0e0e0",
            width: "100%",
          },
        },
        [
          _c("div", { staticClass: "col-1" }, [
            _c("img", {
              staticStyle: { "margin-top": "0px", width: "50px" },
              attrs: { src: "/img/briefing.png" },
            }),
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "font-weight": "700",
                  "margin-left": "20px",
                  "padding-top": "15px",
                },
              },
              [
                _vm._v(
                  "\n            YOUR PROJECT & STARTUP PROFILE IS NOT SETUP\n          "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-3" }, [
            _c(
              "div",
              {
                staticClass: "btn btn-primary",
                staticStyle: { "margin-right": "10px", padding: "15px" },
                attrs: { onClick: "javascript:document.location.reload()" },
              },
              [_vm._v("\n            Setup Profile\n          ")]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }