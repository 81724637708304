var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row",
      staticStyle: {
        padding: "10px",
        "margin-left": "0px",
        border: "0px solid green",
      },
    },
    [
      _vm._l(_vm.founderData, function (card, k) {
        return _c("div", { key: k, staticClass: "col-md-4 col-sm-12" }, [
          _c("div", { staticClass: "founders_card" }, [
            _c(
              "div",
              { staticClass: "row", staticStyle: { border: "0px solid red" } },
              [
                _c("div", { staticClass: "col-2 pr-0" }, [
                  _c("img", {
                    staticClass: "circle-avatar founder-avatar",
                    attrs: {
                      src:
                        "" + (card.avatar === null ? _vm.avatar : card.avatar),
                    },
                  }),
                ]),
                _c("div", { staticClass: "col-10" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-10" }, [
                      _c("div", { staticClass: "founder_card_title" }, [
                        _vm._v(_vm._s(card.name)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "founder_card_role toUpperCase" },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(card.role) +
                              "\n              "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "founder_card_url" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(card.link) +
                            "\n              "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "founder_card_remove",
                          on: {
                            click: function ($event) {
                              return _vm.removeFounder(card)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "15px" },
                            attrs: { src: "/img/cancel.png" },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ])
      }),
      Object.keys(this.founderData).length <= 0
        ? _c("div", { staticClass: "col-4 col-md-4 col-sm-12" }, [
            _c("div", {
              staticClass: "founders_card founder_card_empty",
              attrs: {
                "data-bs-toggle": "modal",
                "data-bs-target": "#addFounder",
              },
            }),
          ])
        : _vm._e(),
      Object.keys(this.founderData).length <= 1
        ? _c("div", { staticClass: "col-md-4 col-sm-12 col-xs-12" }, [
            _c("div", { staticClass: "founders_card founder_card_empty" }),
          ])
        : _vm._e(),
      Object.keys(this.founderData).length <= 2
        ? _c("div", { staticClass: "col-md-4 col-sm-12 col-xs-12" }, [
            _c("div", { staticClass: "founders_card founder_card_empty" }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }