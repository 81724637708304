<template>
  <div class="page-container">
    <profileNavbar v-bind:project_name="project.project_name" />

    <div
      v-if="!hasProject"
      style="
        background-color: #f7f7f8;
        display: flex;
        padding-top: 50px;
        justify-content: center;
      "
      class=""
    >
      <div style="width: 60%">
       

        <div
          class="row"
          style="padding: 20px; background-color: #e0e0e0; width: 100%"
        >
          <div class="col-1">
            <img src="/img/briefing.png" style="margin-top: 0px; width: 50px" />
          </div>
          <div class="col-8">
            <div style="font-weight: 700; margin-left: 20px; padding-top: 15px">
              YOUR PROJECT & STARTUP PROFILE IS NOT SETUP
            </div>
          </div>
          <div class="col-3">
            <div onClick="javascript:document.location.reload()"
              class="btn btn-primary"
              style="margin-right: 10px; padding:15px"
            >
              Setup Profile
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasProject"
      style="background-color: #f7f7f8; display: flex; padding-top: 50px"
      class="tab3 tab-container"
    >
      <div class="card-container" style="width: 100%">
        <div class="row card-item-container">
          <div class="col-md-6 startup-padding">
            <productNameCard
              v-bind="{
                project_name: this.project.project_name,
                overview: this.project.overview,
                kick_off: this.project.kick_off,
                project_id: this.project.id,
              }"
            />
          </div>

          <div class="col-md-6 investor-padding" style="">
            <industryCard
              v-bind:form="industryFields"
              v-bind:project_id="this.project.id"
            />
            <br />

            <platformCard
              v-bind:form="platformFields"
              v-bind:project_id="this.project.id"
            /><br />

            <techStackCard
              v-bind:form="stackFields"
              v-bind:project_id="this.project.id"
              v-bind:startup_id="project.startup.id"
            />
            <br />
            <lookingForCard
              v-bind:form="lookingForFields"
              v-bind:project_id="this.project.id"
              v-bind:startup_id="project.startup.id"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasProject"
      style="background-color: #f7f7f8; display: none"
      class="tab2 tab-container"
    >
      <founderCard
        v-bind="{
          client_id: this.project.client_id,
          project_id: this.project.id,
          founders: JSON.parse(this.foundersData || []),
        }"
      />

      <div class="card-container">
        <div class="row card-item-container">
          <div class="col-md-6 startup-padding">
            <startupNameCard
              v-bind="{
                startup: this.project.hasOwnProperty('startup')
                  ? this.project.startup
                  : [],
                user_type: this.user.user_type,
                project_id: this.project.id,
              }"
            />
          </div>

          <div class="col-md-6 investor-padding" style="">
           
            <investorCard
              v-bind:client_id="project.client_id"
              v-bind:startup_id="project.startup.id"
              v-bind:project_id="project.id"
              v-bind:stage="project.startup.stage.label"
              v-bind:backed_by="project.startup.backed_by"
              v-bind:price="project.price"
              v-bind:form_errors="form_errors"
              v-bind:form="form"
            />
            <preferedCard
              v-bind:client_id="project.client_id"
              v-bind:startup_id="project.startup.id"
              v-bind:project_id="project.id"
              v-bind:compensation="project.compensation_id"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasProject"
      class="profile-cover tab1 tab-container"
      style="padding: 0px; display: none"
    >
      <div
        class="card-container"
        style="
          width: 100%;
          margin-left: 0px;
          padding-left: 40px;
          margin-top: 10px;
        "
      >
        <profile />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { editDevImage } from "@/api/client";
import Navbar from "@/components/general/toolbar";
import profileNavbar from "@/components/general/profileNavbar";
//
import DeactivateAccount from "./deactivateAccount.vue";
import founderCard from "./startupProfile/founderCard.vue";
import startupNameCard from "./startupProfile/startupNameCard.vue";
import investorCard from "./startupProfile/investorCard.vue";
import preferedCard from "./startupProfile/preferedCard.vue";

import industryCard from "./crudApp/industryCard.vue";
import productNameCard from "./crudApp/productNameCard.vue";
import platformCard from "./crudApp/platformCard.vue";
import techStackCard from "./crudApp/techStackCard.vue";
import lookingForCard from "./crudApp/lookingForCard.vue";

import profile from "./personalProfile/profile.vue";

//

//

//

//

export default {
  props: {},
  components: {
    Navbar,
    profileNavbar,
    DeactivateAccount,
    founderCard,
    startupNameCard,
    productNameCard,
    investorCard,
    preferedCard,
    investorCard,
    industryCard,
    platformCard,
    techStackCard,
    lookingForCard,
    profile,
  },

  data: () => ({
    industryFields: {
      industry: [],
    },

    lookingForFields: {
      looking_for: [],
    },

    stackFields: {
      stack: [],
    },
    platformFields: {
      platform: [],
    },
    image: null,
    secSpinner: false,
    profile_spinner: false,
    foundersData: [],
    imageAvatar: "/img/onboarding/profile-avatar.svg",

    form_errors: {
      stage_category_id: [],
      selectedBackedBy: [],
      funding_amount: [],
      backed_by: [],
    },

    form: {
      stage_category_id: 0,
      stage_id: 0,
      backed_by: "",
      selectedBackedBy: "nil",
      compensation_id: 0,
      funding_amount: "",
    },
    //
  }),

  created() {
    this.foundersData = localStorage.getItem("grupa_user_founder");
    // alert(JSON.stringify(JSON.parse(this.foundersData)))
  },
  mounted() {
    //console.log(project.startup.id)
    //console.log('project.startup')

    //this.industryFields.industry = JSON.parse(this.project.industry);

    this.industryFields.industry = JSON.parse(
      this.project.industry === "null" ||
        this.project.industry == "" ||
        this.project.industry == null
        ? "[]"
        : this.project.industry
    );

    this.stackFields.stack = JSON.parse(
      this.project.stack === "null" ||
        this.project.stack == "" ||
        this.project.stack == null
        ? "[]"
        : this.project.stack
    );

    this.platformFields.platform = JSON.parse(
      this.project.project_type === "null" ||
        this.project.project_type == "" ||
        this.project.project_type == null
        ? "[]"
        : this.project.project_type
    );

    this.lookingForFields.looking_for = JSON.parse(
      this.project.looking_for === "null" ||
        this.project.looking_for == "" ||
        this.project.looking_for == null
        ? "[]"
        : this.project.looking_for
    );

    //
    //

    //console.log("Project : ", this.clientProject);
    // console.log("User : ", this.currentUser);

    //
  },
  methods: {
    ...mapActions("userManagement", ["updateUserDetails"]),

    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    openAvaterUpload() {
      this.$refs.avatarInput.click();
    },
    onFilePicked(event) {
      this.secSpinner = true;
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      event = null;
      this.secSpinner = false;
      this.handleProfile();
    },
    handleProfile() {
      this.profile_spinner = true;
      let dataForm = new FormData();
      dataForm.append("profile_picture", this.image);
      editDevImage(dataForm)
        .then((response) => {
          if (response.data.status == 1) {
            // this.successMessage = true;
            // this.successText = "Profile Image Updated";
            // Update Sidebar Photo
            this.$emit("fetchDetails");
            this.updateUserDetails(response.data[0]);
            this.profile_spinner = false;
          } else if (response.data.status == 0) {
            this.errorMessage = true;
            this.errorText = response.data.msg;
          }
        })
        .catch((error) => {
          this.profile_spinner = false;
          this.$developConsole(error, "error");
        });
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", ["clientProject"]),

    hasProject() {
      console.log("hasProject", this.containsKey(this.project, "project_name"));
      return this.containsKey(this.project, "project_name");
    },
    user() {
      return this.currentUser;
    },

    project() {
      return this.clientProject;
    },
  },
};
</script>
<style scoped>
.investor-padding {
  padding-right: 0px;
  margin-top: 10px;
}
.startup-padding {
  padding-left: 20px;
  margin-top: 10px;
}
.card-container {
  margin-left: 37px;
  margin-top: 30px;
  padding-right: 15px;
}

.card-item-container {
  width: 100%;
  padding-left: 5px;
  margin-top: -70px;
  border: 0px solid red;
}
.profile-cover {
  display: flex;
  width: 100%;
  padding: 40px;
  padding-top: 0px;
}
.profile-cover .item {
  background: #ffffff;
  width: 50%;
  margin: 10px;
  border-bottom: 1px solid #eee;
}
.profile-cover .item .layer {
  width: 90%;
  margin: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
}
.profile-cover .item .layer span {
  float: left;
  text-align: left;
  width: 50%;
}
span.title {
  color: #979da5;
  font-size: 12px !important;
  padding-bottom: 30px;
}
.float-to-the-right {
  float: right !important;
  text-align: right !important;
  color: #0781f2;
}
.grid-display {
  display: grid;
}
.profile-cover .item .layer-two {
  width: 90%;
  margin: 24px;
  padding-bottom: 24px;
}
.item-two {
  height: 50%;
}
.layer-two .avatar {
  width: 40%;
  width: 150px;
  height: 150px;
  vertical-align: left;
  border: none;
  background: #54bdd4;
  border-radius: 50%;
  margin: 0 auto;
}
.circle-avatar {
  width: 40%;
  width: 150px;
  height: 150px;
  vertical-align: left;
  border: none;
  background: #54bdd4;
  border-radius: 50%;
  margin: 0 auto;
}
#uploadProfilePic {
  display: none;
}
.mouse {
  cursor: pointer;
}
</style>
