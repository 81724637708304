<template>
  <div
    class="row"
    style="padding: 10px; margin-left: 0px; border: 0px solid green"
  >
    <div class="col-md-4 col-sm-12" v-for="(card, k) in founderData" :key="k">
      <div class="founders_card">
        <div class="row" style="border: 0px solid red">
          <div class="col-2 pr-0">
            <img
              class="circle-avatar founder-avatar"
              style=""
              :src="`${card.avatar === null ? avatar : card.avatar}`"
            />
          </div>
          <div class="col-10">
            <div class="row">
              <div class="col-10">
                <div class="founder_card_title">{{ card.name }}</div>
                <div class="founder_card_role toUpperCase">
                  {{ card.role }}
                </div>
                <div class="founder_card_url">
                  {{ card.link }}
                </div>
              </div>
              <div class="col-2">
                <div class="founder_card_remove" @click="removeFounder(card)">
                  <img src="/img/cancel.png" style="width: 15px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-4 col-md-4 col-sm-12"
      v-if="Object.keys(this.founderData).length <= 0"
    >
      <div
        class="founders_card founder_card_empty"
        data-bs-toggle="modal"
        data-bs-target="#addFounder"
        style=""
      ></div>
    </div>

    <div
      class="col-md-4 col-sm-12 col-xs-12"
      v-if="Object.keys(this.founderData).length <= 1"
    >
      <div class="founders_card founder_card_empty" style=""></div>
    </div>

    <div
      class="col-md-4 col-sm-12 col-xs-12"
      v-if="Object.keys(this.founderData).length <= 2"
    >
      <div class="founders_card founder_card_empty" style=""></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    founderData: { type: Array },
    removeFounder: { type: Function },
    avatar: { type: String },
  },

  methods: {},
};
</script>
<style scoped>
.founder_card_remove {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  cursor: pointer;
  padding-left: 2px;
  /* primary/500 */

  color: #53585f;
}
.toUpperCase {
  text-transform: uppercase;
}
.profile-card-2 {
  text-align: left;
  background: #ffffff;

  border-bottom: 1px solid #eee;
  padding: 20px;
}

.founder_card_empty {
  height: 88px;
  /* primary/50 */
  background: #f7f7f8 !important;
  border-radius: 8px !important;
}

.founder_card_empty:hover {
  height: 88px;
  /* primary/50 */

  background: #ebebee !important;
  border-radius: 8px !important;
}

.founder_card_title {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1b1e22;
}

.founder_card_url {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
  color: #979da5;
}
.founder_card_role {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
  color: #979da5;
}
.founders_card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.founder-avatar {
  height: 56px !important;
  width: 56px !important;
  border: 0px;
}
</style>
