<template>
  <div class="profile-cover" style="display: block">
    <div class="row profile-card" style="padding-top: 20">
      <div class="col-6" style="border: none">
        <span class="title">FOUNDERS</span>

        <!--span class="float-to-the-right">Edit</span-->
      </div>

      <div
        class="col-6"
        style="text-align: right"
        data-bs-toggle="modal"
        data-bs-target="#addFounder"
      >
        <span class="start_up_edit">Add Founder</span>
      </div>

      <div class="bold-sub-title" style="">
        <div style="width: 350px; padding-top: 20px; margin-bottom: 0px">
          Add the linkedIn profile of the founder(s), show the folks behind the
          startup.
        </div>
      </div>

      <founderProfileCard
        v-bind:founderData="founderData"
        v-bind:removeFounder="removeFounder"
        v-bind:avatar="rand(this.avatar)"
      />
    </div>

    <div
      class="modal"
      id="addFounder"
      tabindex="-1"
      aria-labelledby="addFounderLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Founder</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit="addFounderButton">
            <div class="modal-body" style="padding: 20px; padding-top: 50px">
              <span class="modal_label"
                >Add the founder’s linkedin profile and specify their role in
                the company.</span
              ><br /><br />

              <div class="form-group">
                <label class="">Fullname</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  v-on:keyup="updateData"
                  placeholder="Fullname"
                  required
                  class="form-control"
                />
                <!--  <small class="form-text text-muted"
                  >kindly founders fullname</small
                > -->
              </div>

              <!-- 
                 pattern="[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
               -->
              <div class="form-group">
                <label class="">LinkedIn url</label>
                <!--  
                  pattern="https://.*"
                  oninvalid="InvalidMsg"
                  oninput="InvalidMsg"
                -->
                <input
                  type="text"
                  name="url"
                  id="url"
                  v-on:keyup="updateData"
                  placeholder="LinkedIn Profile link"
                 
                 
                  required
                  style="margin: 0px"
                  class="form-control"
                />
                <small class="form-text text-muted"
                  >kindly provide a valid LinkedIn Url.</small
                >
              </div>
              <br />

              <span class="modal_label">Compay role</span>
              <input
                type="text"
                name="role"
                id="role"
                v-on:keyup="updateData"
                placeholder="e.g. CEO"
                required
                class="form-control"
              />
            </div>

            <div class="modal-footer">
              <div
                v-if="isAddingFounder"
                style="right: 0%; right: 0; margin-top: 0%; text-align: center"
                id="loaderCal"
              >
                <v-progress-circular
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                {{ "adding founder..." }}
              </div>

              <button
                v-if="!isAddingFounder"
                type="button"
                class="btn btn-light p-3 lightBorder"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                v-if="!isAddingFounder"
                type="submit"
                class="btn btn-primary confirmTeamProceed p-3"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="errorModal"
      tabindex="-1"
      aria-labelledby="errorModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ERROR DETECTED</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/cancel.png"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Error detected while
              {{ action === "add" ? "creating" : "removing" }} founder
            </p>
            <code>{{
              this.founderCreateError || this.founderRemoveError
            }}</code>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light lightBorder"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="successChangeRequestModal"
      tabindex="-1"
      aria-labelledby="successChangeRequestModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Founder {{ action === "add" ? "Added" : "Removed" }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/marked.svg"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Founders record
              {{ action === "add" ? "created" : "removed" }} successfully
            </p>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-light lightBorder"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="removeFounderModal"
      tabindex="-1"
      aria-labelledby="removeFounderModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Delete Founder
              <span class="badge badge-primary"
                ><h6 style="margin-bottom: 0px">
                  {{ selectedFounder.name }}
                </h6></span
              >
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />

            <p style="margin-top: 30px">
              Are you sure you want to delete this founders record ?
            </p>
            <br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-light lightBorder"
            >
              Close
            </button>

            <div
              v-if="isRemovingFounder"
              style="right: 0%; right: 0; margin-top: 0%; text-align: center"
              id="loaderCal"
            >
              <v-progress-circular
                :size="30"
                color="primary"
                indeterminate
              ></v-progress-circular>
              {{ "removing founder..." }}
            </div>

            <button
              v-if="!isRemovingFounder"
              type="button"
              @click="startFounderRemove"
              class="btn btn-primary"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addFounder, removeFounder } from "@/api/client";
import founderProfileCard from "./founderProfileCard.vue";

export default {
  props: ["client_id", "project_id", "founders"],
  components: { founderProfileCard },

  mounted() {
    this.mapFounderData(this.founders);

    /*  
    let collaboratorsData = [];
    if (Object.keys(this.founders).length > 0) {
      this.founders.map((data, i) => {
        collaboratorsData.push({
          id: data.id,
          name: data.name == null ? "No Name" : data.name,
          role: data.role,
          client_id: data.client_id,
          project_id: data.project_id,
          link:
            data.link.length > 25 ? data.link.slice(0, 25) + "..." : data.link,
          avatar: this.rand(this.avatar),
        });
      });
    } 

     this.founderData = collaboratorsData;
     */
  },

  data: () => ({
    founderUrl: "",
    founderRole: "",
    founderName: "",
    founderData: [],
    founderCreateError: "",
    founderRemoveError: "",
    isAddingFounder: false,
    isRemovingFounder: false,
    selectedFounder: [],
    action: "",
    avatar: [
      //  "https://s3-alpha.figma.com/profile/6bc90d98-f1b0-491c-b359-d76a50d005a0",
      //"https://s3-alpha.figma.com/profile/51ff7275-f4ab-4984-9873-feec90ad934a",
      "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAMDAwMDAwQEBAQFBQUFBQcHBgYHBwsICQgJCAsRCwwLCwwLEQ8SDw4PEg8bFRMTFRsfGhkaHyYiIiYwLTA+PlQBAwMDAwMDBAQEBAUFBQUFBwcGBgcHCwgJCAkICxELDAsLDAsRDxIPDg8SDxsVExMVGx8aGRofJiIiJjAtMD4+VP/CABEIADwAPAMBIgACEQEDEQH/xAAcAAEAAQUBAQAAAAAAAAAAAAAABgEDBAUHAgn/2gAIAQEAAAAA+lYAj8cy5ndHO8VNN0IFrk62gjEYudH9iLQPN6lec+0VmtG038L9UpWh/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAhAAAAAAAP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMQAAAAAAD/xAAuEAABAwMACAUEAwAAAAAAAAABAgMEAAURBhASEyAhQVEiMmKRwSNxcoExQqH/2gAIAQEAAT8A14PHdbuuMvcMY2wPGs88Z6ClT5qzlUh0n8qjXmawoFay8jqlfwaZebkNIdbOUrGRwCpxJmySf53qtdgJNu+zq+G7N7u4yPUoK9xrsyC3bWfUVL9zw6QxiS1IA5Y2F/Gpplb7qGkeZasCkNpabQhPlQkJH64CMDJ5DvWkk+Gu3uRm3wp5ak42Dkp2TnJNC4S2hhyPvT0Wg4z9xVqmuN3SNJl/TabUcITzxkY2j3piRHkjLLrbg9Ks1gjVL0nnvLVuAllHTllf7Jp+VKknLz7jn5K+KAAGNY8JykkHuDg0xe7rG5JkqUB/VY2x/tR9LkBvEmMsud2/KfeumrtXSu9dKFZNf//EABQRAQAAAAAAAAAAAAAAAAAAAED/2gAIAQIBAT8AB//EABQRAQAAAAAAAAAAAAAAAAAAAED/2gAIAQMBAT8AB//Z",
    ],
  }),
  methods: {
    rand(items) {
      return items[(items.length * Math.random()) | 0];
    },
    InvalidMsg(textbox) {
      if (textbox.value === "") {
        textbox.setCustomValidity("Url is required");
      } else if (textbox.validity.typeMismatch) {
        textbox.setCustomValidity("Enter a valid URL to continue");
      } else {
        textbox.setCustomValidity("");
      }

      return true;
    },

    mapFounderData(founders) {
      this.founderData = [];
      let collaboratorsData = [];
      if (Object.keys(founders).length > 0) {
        this.founders.map((data, i) => {
          //  console.log({ i });
          collaboratorsData.push({
            id: data.id,
            name: data.name,
            role: data.role,
            client_id: data.client_id,
            project_id: data.project_id,
            link:
              data.link.length > 25
                ? data.link.slice(0, 25) + "..."
                : data.link,
            avatar: this.rand(this.avatar),
          });
        });
      }

      this.founderData = collaboratorsData;
      // console.log(this.founderData);
      //console.log({ founders });
    },

    async startFounderRemove() {
      this.action = "remove";
      this.isRemovingFounder = true;
      const payload = {
        id: this.selectedFounder.id,
        client_id: this.selectedFounder.client_id,
      };
      //console.log(payload);

      await removeFounder(payload)
        .then((response) => {
          this.founderRemoveError = "";
          this.isRemovingFounder = false;
          $("#removeFounderModal").modal("hide");

          if (response.data.status == 1) {
            this.founderData = response.data.data.founders;
            // this.mapFounderData(response.data.data.founders);

            //  console.log(this.founderData);

            $("#successChangeRequestModal").modal("show");

            //
            localStorage.setItem(
              "grupa_user_founder",
              JSON.stringify(response.data.data.founders)
            );
          } else {
            this.founderRemoveError = response.data.errors;
            $("#errorModal").modal("show");
          }
        })
        .catch((error) => {
          $("#removeFounderModal").modal("hide");
          this.isRemovingFounder = false;
          if (error.hasOwnProperty("message")) {
            this.founderRemoveError = error.message;
          } else {
            this.founderRemoveError = error.data.errors;
          }

          $("#errorModal").modal("show");
        });
    },

    async addFounderButton(event) {
      event.preventDefault();
      this.isAddingFounder = true;
      this.action = "add";
      // alert("yes");

      const payload = {
        client_id: this.client_id,
        project_id: this.project_id,
        name: this.founderName,
        link: this.founderUrl,
        role: this.founderRole,
      };

      await addFounder(payload)
        .then((response) => {
          this.founderCreateError = "";
          this.isAddingFounder = false;
          $("#addFounder").modal("hide");

          if (response.data.status == 1) {
            //console.log(response.data.data.founders);
            this.founderData = response.data.data.founders;
            // this.mapFounderData(response.data.data.founders);

            localStorage.setItem(
              "grupa_user_founder",
              JSON.stringify(response.data.data.founders)
            );

            this.founderName = "";
            this.founderUrl = "";
            this.founderRole = "";
            document.getElementById("url").value = "";
            document.getElementById("role").value = "";
            document.getElementById("name").value = "";

            $("#successChangeRequestModal").modal("show");
          } else {
            this.founderCreateError = response.data.errors;
            $("#errorModal").modal("show");
          }
        })
        .catch((error) => {
          $("#addFounder").modal("hide");
          this.isAddingFounder = false;
          if (error.hasOwnProperty("message")) {
            this.founderCreateError = error.message;
          } else {
            this.founderCreateError = error.data.errors;
          }

          $("#errorModal").modal("show");
        });
    },

    removeFounder(founder) {
      this.selectedFounder = founder;
      $("#removeFounderModal").modal("show");

      //alert(JSON.stringify(this.selectedFounder))
    },
    updateData(evt) {
      let name = evt.target.name;
      let value = evt.target.value;
      if (name === "url") {
        this.founderUrl = value;
      } else if (name === "role") {
        this.founderRole = value;
      } else if (name === "name") {
        this.founderName = value;
      }

      // console.log( this.founderUrl);
      //console.log(this.founderRole);
    },
  },
};
</script>

<style scoped>
.lightBorder {
  border: 1px solid #eee;
}
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* primary/700 (text) */

  color: #1b1e22;
  padding-bottom: 10px;
}

span.title {
  color: #979da5;
  font-size: 12px !important;
  padding-bottom: 30px;
}
.start_up_data {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-top: 30px;
  padding-bottom: 20px;

  color: #1b1e22;
}

.bold-sub-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
}

.profile-card {
  text-align: left;
  background: #ffffff;
  width: 100%;
  margin: 10px;
  border-bottom: 1px solid #eee;
  padding: 20px;
}
.start_up_edit {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;

  text-align: right;
  cursor: pointer;
  color: #0781f2;
}
.start_up_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  text-transform: uppercase;

  color: #979da5;
}
</style>
