var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box profile-card-2" }, [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "start_up_data" }, [
          _vm._v(_vm._s(_vm.project_name)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "start_up_line" }),
    _c(
      "div",
      {
        staticClass: "row",
        staticStyle: { "padding-top": "30px", "padding-bottom": "20px" },
      },
      [
        _c(
          "div",
          {
            staticClass: "col-sm-6",
            staticStyle: {
              "text-align": "left",
              "margin-top": "-10px",
              "padding-top": "10px",
            },
          },
          [
            _c("span", { staticClass: "start_up_label" }, [
              _vm._v("PRODUCT DESCRIPTION"),
            ]),
            _c("div", { staticClass: "start_up_data" }, [
              _vm._v("\n        " + _vm._s(_vm.overview) + "\n      "),
            ]),
          ]
        ),
      ]
    ),
    _c("div", { staticClass: "start_up_line" }),
    _c(
      "div",
      {
        staticClass: "row",
        staticStyle: { "padding-top": "30px", "min-height": "143px" },
      },
      [
        _c(
          "div",
          {
            staticClass: "col-sm-12",
            staticStyle: {
              "text-align": "left",
              "margin-top": "-10px",
              "padding-top": "10px",
            },
          },
          [
            _c("span", { staticClass: "start_up_label" }, [
              _vm._v("LOOKING TO START"),
            ]),
            _c("div", { staticClass: "start_up_data" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.parseKickoff(_vm.kick_off)) +
                  "\n      "
              ),
            ]),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "editproductName",
          tabindex: "-1",
          "aria-labelledby": "editproductNameLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _c("form", { on: { submit: _vm.editproductNameFunc } }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    staticStyle: { padding: "20px", "padding-top": "50px" },
                  },
                  [
                    _c("span", { staticClass: "modal_label" }, [
                      _vm._v(
                        "Edit product name, description, location and looking to\n              start."
                      ),
                    ]),
                    _c("br"),
                    _c("br"),
                    _c("span", { staticClass: "modal_label" }, [
                      _vm._v("Product name"),
                    ]),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "projectName",
                        id: "projectName",
                        placeholder: "Project Name",
                        required: "",
                      },
                      domProps: { value: "" + _vm.project_name },
                      on: { keyup: _vm.updateData },
                    }),
                    _c("span", { staticClass: "modal_label" }, [
                      _vm._v("Description"),
                    ]),
                    _c("textarea", {
                      staticClass: "form-control",
                      staticStyle: { height: "70px" },
                      attrs: {
                        name: "projectDesc",
                        id: "projectDesc",
                        placeholder: "Project Description",
                        required: "",
                      },
                      domProps: { value: "" + _vm.overview },
                      on: { keyup: _vm.updateData },
                    }),
                    _c("span", { staticClass: "modal_label" }, [
                      _vm._v("Looking to start"),
                    ]),
                    _c("div", { staticClass: "chip-btn-container" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.projectKickOff,
                              expression: "projectKickOff",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { name: "lookingFor", id: "lookingFor" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.projectKickOff = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.updateData(
                                  $event,
                                  $event.target.selectedIndex
                                )
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.startPeriod, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.value } },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(item.name) +
                                  "\n                "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "modal-footer" }, [
                  _vm.isUpdateProduct
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            right: "0",
                            "margin-top": "0%",
                            "text-align": "center",
                          },
                          attrs: { id: "loaderCal" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 30,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s("updating product...") +
                              "\n            "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isUpdateProduct
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-light lightBorder p-3",
                          attrs: { type: "button", "data-bs-dismiss": "modal" },
                        },
                        [_vm._v("\n              Cancel\n            ")]
                      )
                    : _vm._e(),
                  !_vm.isUpdateProduct
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary p-3",
                          attrs: { type: "submit" },
                        },
                        [_vm._v("\n              Update\n            ")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "errorModalProductName",
          tabindex: "-1",
          "aria-labelledby": "errorModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("br"),
                  _c("img", {
                    staticStyle: { "margin-top": "30px", width: "150px" },
                    attrs: { src: "/img/cancel.png" },
                  }),
                  _c("br"),
                  _c("br"),
                  _c("p", { staticStyle: { "margin-top": "30px" } }, [
                    _vm._v(
                      "\n            Error detected while updating product info\n          "
                    ),
                  ]),
                  _c("code", [_vm._v(_vm._s(this.projectUpdateError))]),
                  _c("br"),
                  _c("br"),
                ]
              ),
              _vm._m(3),
            ]),
          ]
        ),
      ]
    ),
    _vm._m(4),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6", staticStyle: { "text-align": "left" } },
        [
          _c("span", { staticClass: "start_up_label" }, [
            _vm._v("PRODUCT NAME"),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "col-6",
          staticStyle: { "text-align": "right" },
          attrs: {
            "data-bs-toggle": "modal",
            "data-bs-target": "#editproductName",
          },
        },
        [_c("span", { staticClass: "start_up_edit" }, [_vm._v("Edit")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Edit Product Info")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("ERROR DETECTED")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light lightBorder",
          attrs: { type: "button", "data-bs-dismiss": "modal" },
        },
        [_vm._v("\n            Close\n          ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "successChangeRequestModalProductName",
          tabindex: "-1",
          "aria-labelledby": "successChangeRequestModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Product Info Updated"),
                ]),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: {
                    type: "button",
                    "data-bs-dismiss": "modal",
                    "aria-label": "Close",
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("br"),
                  _c("img", {
                    staticStyle: { "margin-top": "30px", width: "150px" },
                    attrs: { src: "/img/marked.svg" },
                  }),
                  _c("br"),
                  _c("br"),
                  _c("p", { staticStyle: { "margin-top": "30px" } }, [
                    _vm._v(
                      "\n            Product info record updated successfully\n          "
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                ]
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light lightBorder",
                    attrs: { type: "button", "data-bs-dismiss": "modal" },
                  },
                  [_vm._v("\n            Close\n          ")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }