<template>
  <div>
    <div class="nav-wrapper">
      <div class="stage-title " data="tab1">Personal Profile</div>

      <div class="stage-title " data="tab2">Startup Profile</div>
      <div class="stage-title active" data="tab3">{{project_name || "No Project"}}</div>

      
    </div>
  </div>
</template>
<script>
import jquery from "jquery";

export default {
  props:['project_name'],
  mounted() {
    this.$nextTick(async () => {
      jquery("body").on("click", ".stage-title", function () {
        jquery(".stage-title").removeClass("active");
        jquery(this).addClass("active");
        const tab = jquery(this).attr("data");
        jquery(".tab-container").hide(() => {
        //  alert(tab)
           // console.log(tab)
          jquery(`.${tab}`).show();
        });
      });
    });
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.bordered{
  border:2px soild red !important;
}
.nav-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  width: calc(100% - 200px);
  top: 0px;
  left: auto;
  border-left:0px solid red;
  /* z-index: 200; */
}
.stage-title {
  text-align: initial;
  padding-top: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-left: 30px;

  color: #979da5;
  cursor: pointer;
}

.active {
  border-bottom: 1px solid #1b1e22;
  color: #1b1e22;
}
</style>
