<template>
  <div class="box profile-card-2">
    <div class="row">
      <div class="col-6" style="text-align: left">
        <span class="start_up_label">PRODUCT NAME</span>
      </div>
      <div
        class="col-6"
        style="text-align: right"
        data-bs-toggle="modal"
        data-bs-target="#editproductName"
      >
        <span class="start_up_edit">Edit</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="start_up_data">{{ project_name }}</div>
      </div>
    </div>

    <div class="start_up_line"></div>

    <div class="row" style="padding-top: 30px; padding-bottom: 20px">
      <div
        class="col-sm-6"
        style="text-align: left; margin-top: -10px; padding-top: 10px"
      >
        <span class="start_up_label">PRODUCT DESCRIPTION</span>

        <div class="start_up_data">
          {{ overview }}
        </div>
      </div>
    </div>

    <div class="start_up_line"></div>
    <div class="row" style="padding-top: 30px; min-height: 143px">
      <div
        class="col-sm-12"
        style="text-align: left; margin-top: -10px; padding-top: 10px"
      >
        <span class="start_up_label">LOOKING TO START</span>

        <div class="start_up_data">
          {{ parseKickoff(kick_off) }}
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="editproductName"
      tabindex="-1"
      aria-labelledby="editproductNameLabel"
      aria-hidden="true"
      style=""
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Product Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit="editproductNameFunc">
            <div class="modal-body" style="padding: 20px; padding-top: 50px">
              <span class="modal_label"
                >Edit product name, description, location and looking to
                start.</span
              ><br /><br />
              <!--  :value="`${startup.name}`" -->
              <span class="modal_label">Product name</span>
              <input
                type="text"
                name="projectName"
                id="projectName"
                :value="`${project_name}`"
                v-on:keyup="updateData"
                placeholder="Project Name"
                required
                class="form-control"
              />

              <!--  :value="`${startup.description}`" -->
              <span class="modal_label">Description</span>
              <textarea
                name="projectDesc"
                id="projectDesc"
                :value="`${overview}`"
                v-on:keyup="updateData"
                placeholder="Project Description"
                required
                class="form-control"
                style="height: 70px"
              />

              <!-- :value="`${startup.location == null ? '' : startup.location}`" -->
              <span class="modal_label">Looking to start</span>
              <div class="chip-btn-container">
                <select
                  v-on:change="updateData($event, $event.target.selectedIndex)"
                  class="form-control"
                  name="lookingFor"
                  id="lookingFor"
                  v-model="projectKickOff"
                >
                  <option
                    v-for="(item, index) in startPeriod"
                    v-bind:value="item.value"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="modal-footer">
              <div
                v-if="isUpdateProduct"
                style="right: 0%; right: 0; margin-top: 0%; text-align: center"
                id="loaderCal"
              >
                <v-progress-circular
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                {{ "updating product..." }}
              </div>

              <button
                v-if="!isUpdateProduct"
                type="button"
                class="btn btn-light lightBorder p-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                v-if="!isUpdateProduct"
                type="submit"
                class="btn btn-primary p-3"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="errorModalProductName"
      tabindex="-1"
      aria-labelledby="errorModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ERROR DETECTED</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/cancel.png"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Error detected while updating product info
            </p>
            <code>{{ this.projectUpdateError }}</code>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light lightBorder"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="successChangeRequestModalProductName"
      tabindex="-1"
      aria-labelledby="successChangeRequestModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Product Info Updated</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/marked.svg"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Product info record updated successfully
            </p>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-light lightBorder"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//
import { updateProductUp } from "@/api/client";
import { mapActions } from "vuex";

export default {
  props: ["overview", "kick_off", "project_name", "project_id"],
  data: () => ({
    isUpdateProduct: false,
    projectUpdateError: "",
    projectName: "",

    projectDesc: "",
    projectKickOff: "",
    startPeriod: [
      {
        name: "ASAP",
        value: 1,
      },
      {
        name: "1 Week",
        value: 2,
      },
      {
        name: "2 Week",
        value: 3,
      },
      {
        name: "3 Week",
        value: 4,
      },
    ],
  }),
  components: {},
  mounted() {
    //console.log(this.startup);
    this.projectName = this.project_name;
    this.projectDesc = this.overview;
    this.projectKickOff = this.kick_off;
    // this.startUpLocation = this.startup.location;
    // this.startUpBio = this.startup.description;
  },
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    parseKickoff(k) {
      try {
        if (k == null) {
          return "";
        }
        switch (k) {
          case 1:
            return "ASAP";
          case 2:
            return "1 Week";
          case 3:
            return "2 Weeks";
          case 4:
            return "3 Weeks";

          default:
            return "";
        }
      } catch (error) {
        // console.error(error);
        return "";
      }
    },
    async editproductNameFunc(event) {
      event.preventDefault();

      const payload = {
        kick_off: this.projectKickOff,
        description: this.projectDesc,
        project_name: this.projectName,
        project_id: this.project_id,
      };

      //
      //console.log(payload);

      this.isUpdateProduct = true; 
      await updateProductUp(payload)
        .then(async (response) => {
          $("#editproductName").modal("hide");
          this.isUpdateProduct = false;

          const { project } = response.data.data;

          if (response.data.status == 1) {
            this.updateProject(project);

            $("#successChangeRequestModalProductName").modal("show");
            // window.location.reload();

            //
          } else {
            this.projectUpdateError = response.data.errors;
            $("#errorModalProductName").modal("show");
          }
        })
        .catch((error) => {
          $("#editproductName").modal("hide");
          this.isUpdateProduct = false;
          if (error.hasOwnProperty("message")) {
            this.projectUpdateError = error.message;
          } else {
            this.projectUpdateError = error.data.errors;
          }

          $("#errorModalProductName").modal("show");
        }); 
    },
    updateData(evt, index) {
      let name = evt.target.name;
      let value = evt.target.value;
      if (name === "projectName") {
        this.projectName = value;
      } else if (name === "projectDesc") {
        this.projectDesc = value;
      } else if (name === "lookingFor") {
        // alert(index+1)
        this.projectKickOff = index+1;
      }
    },
  },
};
</script>

<style scoped>
.lightBorder {
  border: 1px solid #eee;
}
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* primary/700 (text) */

  color: #1b1e22;
  padding-bottom: 10px;
}

.profile-card-2 {
  text-align: left;
  background: #ffffff;

  border-bottom: 1px solid #eee;
  padding: 20px;
}
.start_up_line {
  border: 0.5px solid #e4e5e7;
}

.start_up_data {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-top: 30px;
  padding-bottom: 20px;

  color: #1b1e22;
}
.start_up_edit {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;

  text-align: right;
  cursor: pointer;
  color: #0781f2;
}
.start_up_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  text-transform: uppercase;

  color: #979da5;
}
</style>
