var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "row " }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "box profile-card-2" }, [
          _vm._m(0),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "start_up_data" }, [
                _vm._v(_vm._s(_vm.user.name)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "start_up_line" }),
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: { "padding-top": "30px", "min-height": "143px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-sm-12",
                  staticStyle: {
                    "text-align": "left",
                    "margin-top": "-10px",
                    "padding-top": "10px",
                  },
                },
                [
                  _c("span", { staticClass: "start_up_label" }, [
                    _vm._v("email address"),
                  ]),
                  _c("div", { staticClass: "start_up_data" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.user.email) + "\n        "
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "start_up_line" }),
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: { "padding-top": "30px", "padding-bottom": "20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-sm-6",
                  staticStyle: {
                    "text-align": "left",
                    "margin-top": "-10px",
                    "padding-top": "10px",
                  },
                },
                [
                  _c("span", { staticClass: "start_up_label" }, [
                    _vm._v("phone number"),
                  ]),
                  _c("div", { staticClass: "start_up_data" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          (_vm.user.phone_number_code || "") +
                            "  " +
                            (_vm.user.phone_number || "") +
                            " "
                        ) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "col-sm-6",
                  staticStyle: {
                    "text-align": "left",
                    "border-left": "1px solid #e4e5e7",
                    "margin-top": "-10px",
                    "margin-left": "-20px",
                    "padding-left": "20px",
                    "padding-top": "10px",
                  },
                },
                [
                  _c("span", { staticClass: "start_up_label" }, [
                    _vm._v("location"),
                  ]),
                  _c("div", { staticClass: "start_up_data" }, [
                    _vm._v(_vm._s(_vm.user.timezone)),
                  ]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "start_up_line" }),
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: { "padding-top": "30px", "min-height": "143px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-sm-12",
                  staticStyle: {
                    "text-align": "left",
                    "margin-top": "-10px",
                    "padding-top": "10px",
                  },
                },
                [
                  _c("span", { staticClass: "start_up_label" }, [
                    _vm._v("PERSONAL BIO"),
                  ]),
                  _c("div", { staticClass: "start_up_data" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.user.bio) + "\n        "
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          {
            staticClass: "box profile-card-2",
            staticStyle: { "min-height": "560px" },
          },
          [
            _c(
              "div",
              { staticClass: "layer", staticStyle: { border: "none" } },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v("PROFILE PICTURE "),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "float-to-the-right mouse",
                    on: {
                      click: function ($event) {
                        return _vm.openAvaterUpload()
                      },
                    },
                  },
                  [
                    _vm._v("\n            Change \n\n            "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.secSpinner,
                          expression: "secSpinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
                _c("input", {
                  ref: "avatarInput",
                  attrs: {
                    accept: "image/*",
                    type: "file",
                    id: "uploadProfilePic",
                  },
                  on: { change: _vm.onFilePicked },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "layer-two",
                staticStyle: { "margin-top": "16%" },
              },
              [
                _c("div", { staticClass: "avatar" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.profile_spinner,
                        expression: "profile_spinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                  _c("img", {
                    staticClass: "circle-avatar",
                    attrs: {
                      src:
                        _vm.$imageUrl + _vm.user.profile_picture ||
                        _vm.imageAvatar,
                    },
                  }),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              border: "0px solid red",
              "margin-top": "15px",
              "background-color": "#fff",
            },
          },
          [_c("DeactivateAccount")],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6", staticStyle: { "text-align": "left" } },
        [_c("span", { staticClass: "start_up_label" }, [_vm._v("FULL NAME")])]
      ),
      _c(
        "div",
        {
          staticClass: "col-6",
          staticStyle: { "text-align": "right" },
          attrs: {
            "data-bs-toggle": "modal",
            "data-bs-target": "#editStartUpInfo2",
          },
        },
        [
          _c(
            "span",
            { staticClass: "start_up_edit", staticStyle: { display: "none" } },
            [_vm._v("Edit")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }