<template>
  <div class="profile-card-2">
    <div class="row">
      <div class="col-6" style="text-align: left">
        <span class="start_up_label">investors</span>
      </div>
      <div
        class="col-6"
        style="text-align: right"
        data-bs-toggle="modal"
        data-bs-target="#editInvestorInfo"
      >
        <span class="start_up_edit">Edit</span>
      </div>
    </div>

    <div class="row investor_list">
      <div
        class="investor_data"
        v-for="(data, i) in JSON.parse(
          isEditInvestorField ?   backed_by:formBacked_by
        )"
        :key="i"
      >
        {{ data }}
      </div>
    </div>

    <div class="start_up_line"></div>

    <div class="row" style="padding-top: 30px; padding-bottom: 20px">
      <div
        class="col-sm-6"
        style="text-align: left; margin-top: -10px; padding-top: 10px"
      >
        <span class="start_up_label">startup stage</span>

        <div class="start_up_data">{{ stage.replace("Mood", "Round") }}</div>
      </div>
      <div
        class="col-sm-6"
        style="
          text-align: left;
          border-left: 1px solid #e4e5e7;
          margin-top: -10px;
          margin-left: -20px;
          padding-left: 20px;
          padding-top: 10px;
        "
      >
        <span class="start_up_label">current valuation</span>

        <div class="start_up_data">${{ amountFormat(this.price) }}</div>
      </div>
    </div>

    <div
      class="modal"
      id="editInvestorInfo"
      tabindex="-1"
      aria-labelledby="editInvestorInfoLabel"
      aria-hidden="true"
      style=""
    >
      <div class="modal-dialog" style="width: 550px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Investors Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit="addInvestorButton">
            <div class="modal-body" style="padding: 20px; padding-top: 50px">
              <span class="modal_label"
                >Add your startup’s investors, stage and current
                valuation.</span
              ><br /><br />

              <div class="form-grouped">
                <div class="div-label">
                  Investor name(s)

                  <div class="text-box-hint" style="">
                    Separate VC names with a comma.
                  </div>
                </div>

                <!--   -->
                <div class="grouped-form medium-combo-box">
                  <input
                    type="text"
                    name="backed_by"
                    id="backed_by"
                    v-on:keyup="updateData"
                    placeholder="Investor name(s), e.g. Founders Fund, Khosla Venuters, etc.."
                    required
                    style="margin: 0px"
                    class="form-control"
                    :value="`${String(JSON.parse(backed_by))}`"
                  />

                  <!-- :items="investorList"
              item-value="name"
              item-text="name" -->
                </div>

                <div class="form-grouped">
                  <div class="div-label">Startup stage</div>
                  <div class="light-label text-box-hint">
                    What stage is your startup?
                  </div>

                  <div class="chip-btn-container">
                    <select
                      v-on:change="
                        updateData($event, $event.target.selectedIndex)
                      "
                      class="form-control"
                      name="stage"
                      id="stage"
                      v-model="v_model_stage"
                    >
                      <option
                        v-for="(item, index) in startupStages"
                        v-bind:value="item.name"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-grouped">
                  <div class="div-label">Current valuation ($)</div>

                  <div class="textbox-wrapper">
                    <input
                      class="form-control"
                      name="price"
                      v-model="price"
                      v-on:keyup="updateData"
                      autocomplete="off"
                      placeholder="What’s your current valuation"
                      id="price"
                      maxlength="11"
                      type="text"
                    />
                    <!-- @input="" -->
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <div
                v-if="isUpdateInvestor"
                style="right: 0%; right: 0; margin-top: 0%; text-align: center"
                id="loaderCal"
              >
                <v-progress-circular
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                {{ "updating investor..." }}
              </div>

              <button
                v-if="!isUpdateInvestor"
                type="button"
                class="btn btn-light lightBorder p-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                v-if="!isUpdateInvestor"
                type="submit"
                class="btn btn-primary p-3"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="errorModalInvestor"
      tabindex="-1"
      aria-labelledby="errorModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ERROR DETECTED</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/cancel.png"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Error detected while updating investor info
            </p>
            <code>{{ this.investorInfoUpdateError }}</code>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light lightBorder"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="successChangeRequestModalInvestor"
      tabindex="-1"
      aria-labelledby="successChangeRequestModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Investor Info Updated</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/marked.svg"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Investor info record updated successfully
            </p>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-light lightBorder"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipButton from "@/components/chipButton/chipButton";
import { mapActions } from "vuex";

import { updateInvestor } from "@/api/client";

export default {
  props: [
    "stage",
    "backed_by",
    "price",
    "form_errors",
    "form",
    "client_id",
    "project_id",
    "startup_id",
  ],
  components: { ChipButton },
  data: () => ({
    isEditInvestorField: false,
    formStage: "",
    formStageIndex: "",
    stageInitial: "",
    formBacked_by: "",
    formPrice: "",
    isUpdateInvestor: false,
    investorInfoUpdateError: "",
    v_model_stage: "",
    startupStages: [
      {
        name: "Angel Round",
        value: 1,
        img: "angel.svg",
      },
      {
        name: "Pre-Seed Round",
        value: 2,
        img: "pre_seed.svg",
      },
      {
        name: "Seed Round",
        value: 3,
        img: "seed.svg",
      },
      {
        name: "Series A Round",
        value: 4,
        img: "series_a.svg",
      },
    ],
  }),
  mounted() {
    this.formBacked_by = this.backed_by;
    this.formStage = this.stage;
    this.formPrice = this.price;
    this.v_model_stage = this.parseStageInverse(this.stage);
  },
  computed() {},
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    amountFormat(num) {
      return parseInt(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    parseStage(a) {
      try {
        if (a == null) {
          return "";
        }
        switch (a) {
          case 1:
            return "Angel";
          case 2:
            return "Pre-Seed";
          case 3:
            return "Seed";
          case 4:
            return "Series A";

          default:
            return "";
        }
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    parseStageInverse(a) {
      try {
        if (a == null) {
          return "";
        }
        switch (a) {
          case "Angel":
            this.formStageIndex = 1;
            return "Angel Round";
          case "Pre-Seed":
            this.formStageIndex = 2;
            return "Pre-Seed Round";
          case "Seed":
            this.formStageIndex = 3;
            return "Seed Round";
          case "Series A":
            this.formStageIndex = 4;
            return "Series A Round";

          default:
            return "";
        }
      } catch (error) {
        console.error(error);
        return "";
      }
    },

    formatNumber(value) {
      let currency = value;
      if (currency.includes(",")) {
        currency = currency.replace(/,/g, "");
      }
      return Number(currency).toLocaleString();
    },

    handleFormatDigit(type) {
      let value = this.form[type];
      if (value) {
        this.form[type] = this.formatNumber(value);
      }
    },

    async addInvestorButton(event) {
      event.preventDefault();

      let currentDate = new Date().toJSON().slice(0, 10);
      let time = new Date();

      const date_time = `${currentDate} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;

      //console.log(typeof this.formBacked_by);
      //console.log(this.formBacked_by);
      // console.log("  ::  ", typeof this.backed_by);

      //this.
      const payload = {
        startup_id: this.startup_id,
        client_id: this.client_id,
        project_id: this.project_id,
        backed_by: this.isEditInvestorField
          ? this.formBacked_by.split(",")
          : JSON.parse(this.formBacked_by),
        stage: {
          created_at: date_time,
          id: this.formStageIndex,
          label: this.parseStage(this.formStageIndex) + " Mood",
          name: (
            this.parseStage(this.formStageIndex).split(" ", 2)[0] + "_mood"
          ).toLowerCase(),
          updated_at: date_time,
        },
        price: this.formPrice,
      };

      // console.log(payload);

      this.isUpdateInvestor = true;

      await updateInvestor(payload)
        .then(async (response) => {
          this.isUpdateInvestor = false;
          $("#editInvestorInfo").modal("hide");

          const { project } = response.data.data;

          if (response.data.status == 1) {
            this.updateProject(project);

            $("#successChangeRequestModalInvestor").modal("show");
            // window.location.reload();

            //
          } else {
            this.investorInfoUpdateError = response.data.errors;
            $("#errorModalInvestor").modal("show");
          }
        })
        .catch((error) => {
          $("#editInvestorInfo").modal("hide");
          this.isUpdateInvestor = false;
          if (error.hasOwnProperty("message")) {
            this.investorInfoUpdateError = error.message;
          } else {
            this.investorInfoUpdateError = error.data.errors;
          }

          $("#errorModalInvestor").modal("show");
        });

      // console.log(payload);
    },

    updateData(evt, index) {
      let name = evt.target.name;
      let value = evt.target.value;
      if (name === "backed_by") {
        this.isEditInvestorField = true;
        this.formBacked_by = value;
      } else if (name === "stage") {
        this.formStage = value;
        this.formStageIndex = index + 1;
      } else if (name === "price") {
        this.formPrice = value;
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  border: 1px solid #e4e5e7;
}
.form-control {
  margin-top: 4px;
  margin-bottom: 0px;
}
.form-grouped {
  margin-top: 38px;
}

.form_errors {
  color: red;
}
.light-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
  text-align: left;
}

.chip-btn-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 8px;
}
.textbox-wrapper {
  display: flex;
}
.textbox-wrapper div {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  /* background-color: #fff; */
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0;
  width: 42px;
  background: black;
  color: white;
  height: 51px;
}
.div-label {
  display: flex;
  justify-content: space-between;
}
.medium-combo-box .v-text-field {
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120%;
}

.grouped-form .v-text-field {
  /* border: 1px solid #e4e5e7; */
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120%;
}

.text-box-hint {
  font-size: 12px;
  line-height: 100%;
  color: #979da5;
  text-align: left;
  margin-top: 8px;
}
.profile-card-2 {
  text-align: left;
  background: #ffffff;

  border-bottom: 1px solid #eee;
  padding: 20px;
}
.investor_data {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: auto;

  height: 31px;

  /* primary/0 */

  background: #ffffff;
  /* primary/100 */

  border: 1px solid #e4e5e7;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.investor_list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 20px;
  padding-top: 20px;
}
.start_up_line {
  border: 0.5px solid #e4e5e7;
}

.start_up_data {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-top: 30px;
  padding-bottom: 20px;

  color: #1b1e22;
}
.start_up_edit {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;

  text-align: right;
  cursor: pointer;
  color: #0781f2;
}
.start_up_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  text-transform: uppercase;

  color: #979da5;
}
</style>
