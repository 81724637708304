<template>
  <div class="box profile-card-2">
    <div class="row">
      <div class="col-6" style="text-align: left">
        <span class="start_up_label">Platform</span>
      </div>
      <div
        class="col-6"
        style="text-align: right"
        data-bs-toggle="modal"
        data-bs-target="#editPlatform"
      >
        <span class="start_up_edit">Edit</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row investor_list">
          <div
            class="investor_data"
            v-for="(data, i) in form.platform"
            :key="i"
          >
           <!--  {{ data }} -->
            {{ getPlatformNameFromCode(data) }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="editPlatform"
      tabindex="-1"
      aria-labelledby="editPlatformLabel"
      aria-hidden="true"
      style=""
    >
      <div class="modal-dialog" style="width: 550px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Platform</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit="updatePlatform">
            <div class="modal-body" style="padding: 30px; padding-top: 50px">
              <div class="form-grouped">
                <div class="list-wrapper" v-if="isLoadingPlatforms == true">
                  <img
                    src="/img/lightbox/preloader.gif"
                    style="margin-left: 10px"
                    class="button-spinner"
                  />
                </div>
                <div class="list-wrapper" v-if="isLoadingPlatforms == false">
                  <ChipButton
                    v-for="(item, index) in platformsData"
                    :key="index"
                    :text="item.name"
                    :dashedBorder="true"
                    :buttonIcon="item.img"
                    :selected="checkedSelectedItem(item.tag)"
                    v-on:click="handleSelectIndustry(item.tag)"
                    >{{ item.img }}</ChipButton
                  >
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <div
                v-if="isUpdateIndustry"
                style="right: 0%; right: 0; margin-top: 0%; text-align: center"
                id="loaderCal"
              >
                <v-progress-circular
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                {{ "updating platform..." }}
              </div>

              <button
                v-if="!isUpdateIndustry"
                type="button"
                class="btn btn-light lightBorder p-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                v-if="!isUpdateIndustry"
                type="submit"
                class="btn btn-primary p-3"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="errorModalPlatform"
      tabindex="-1"
      aria-labelledby="errorModalPlatformLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ERROR DETECTED</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/cancel.png"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Error detected while updating industry
            </p>
            <code>{{ this.PlatformInfoUpdateError }}</code>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light lightBorder"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="successChangeRequestModalPlatform"
      tabindex="-1"
      aria-labelledby="successChangeRequestModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Industry Updated</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/marked.svg"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">Industry record updated successfully</p>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-light lightBorder"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePlatform } from "@/api/client";
import ChipButton from "@/components/chipButton/chipButton";

import { mapActions } from "vuex";
import { getIndustry } from "@/api/client";
import { getPlatform, getRoles } from "@/api/client";

export default {
  props: ["form", "project_id"],
  data: () => ({
    IndustryInfoUpdateError: "",
    projectIndustriesData: [],
    isLoadingIndustry: true,

    PlatformInfoUpdateError: "",
    isLoadingPlatforms: true,
    platformsData: [],

    isLoadingRoles: true,
    teamRolesArrData: [],
    isUpdateIndustry: false,
  }),
  components: {
    ChipButton,
  },
  created() {
    getRoles()
      .then((data) => {
        //console.log(data.data.data)
        let response = data.data.data;
        this.isLoadingRoles = false;

        for (var i = 0; i < response.length; i++) {
          this.teamRolesArrData.push({
            name: response[i].name,
            value: response[i].id,
            id: response[i].id,
            img: response[i].img,
          });
        }

        // console.log(this.teamRolesArrData);
        //console.log(this.projectIndustries)
      })
      .catch((error) => {
        this.isLoadingRoles = false;
        console.log(error);
      });

    getPlatform()
      .then((data) => {
        //console.log(data.data.data)
        let response = data.data.data;
        this.isLoadingPlatforms = false;

        for (var i = 0; i < response.length; i++) {
          this.platformsData.push(
            //{ name: response[i].industry_name, tag:response[i].industry_code, img: response[i].industry_svg },
            {
              name: response[i].platforms_name,
              tag: response[i].platforms_code,
              img: `/img/onboarding/${response[i].platforms_svg}`,
              //class: "is_mobile",
              class: response[i].platforms_code,
            }
          );
        }

        // console.log(this.platformsData);
        //console.log(this.projectIndustries)
      })
      .catch((error) => {
        this.isLoadingPlatforms = false;
        console.log(error);
      });

    getIndustry()
      .then((data) => {
        let response = data.data.data;
        this.isLoadingPlatforms = false;

        for (var i = 0; i < response.length; i++) {
          this.projectIndustriesData.push({
            name: response[i].industry_name,
            tag: response[i].industry_code,
            img: response[i].industry_svg,
          });
        }
      })
      .catch((error) => {
        this.isLoadingPlatforms = false;
      });
  },

  computed: {
    checkedSelectedItem() {
      return (value, type) => {
        let selected = this.form.platform;
        const foundItem = (selected || []).find((item) => item == value);
        return foundItem ? true : false;
      };
    },
  },
  mounted() {
    console.log(this.form.platform);
    //console.log("form:: ", this.form.platform);
    // console.log("all:: ", this.projectIndustriesData);
  },
  methods: {
    getPlatformNameFromCode(code) {
      let pName = (this.platformsData || []).find((item) => item.tag == code);
      return "name" in pName ? pName.name : "None";
    },
    ...mapActions("clientDashboard", ["updateProject"]),
    handleSelectIndustry(value) {
      let selected = this.form.platform;
      const currentValue = (selected || []).find((item) => item == value);
      if (!currentValue) {
        this.form.platform.push(value);
      } else {
        this.form.platform = selected.filter((item) => item !== value);
      }
      this.form_errors.platform = [];
    },
    async updatePlatform(event) {
      event.preventDefault();

      const payload = {
        project_id: this.project_id,

        platform: this.form.platform,
      };

      this.isUpdateIndustry = true;

      await updatePlatform(payload)
        .then(async (response) => {
          this.isUpdateIndustry = false;
          $("#editPlatform").modal("hide");

          const { project } = response.data.data;

          if (response.data.status == 1) {
            this.updateProject(project);

            $("#successChangeRequestModalPlatform").modal("show");

            //
          } else {
            this.PlatformInfoUpdateError = response.data.errors;
            $("#errorModalPlatform").modal("show");
          }
        })
        .catch((error) => {
          $("#editPlatform").modal("hide");
          this.isUpdateIndustry = false;
          if (error.hasOwnProperty("message")) {
            this.PlatformInfoUpdateError = error.message;
          } else {
            this.PlatformInfoUpdateError = error.data.errors;
          }

          $("#errorModalPlatform").modal("show");
        });

      //console.log(payload);
    },
  },
};
</script>

<style scoped>
.lightBorder {
  border: 1px solid #eee;
}
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* primary/700 (text) */

  color: #1b1e22;
  padding-bottom: 10px;
}

.profile-card-2 {
  text-align: left;
  background: #ffffff;

  border-bottom: 1px solid #eee;
  padding: 20px;
}
.start_up_line {
  border: 0.5px solid #e4e5e7;
}

.start_up_data {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-top: 30px;
  padding-bottom: 20px;

  color: #1b1e22;
}
.start_up_edit {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;

  text-align: right;
  cursor: pointer;
  color: #0781f2;
}
.start_up_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  text-transform: uppercase;

  color: #979da5;
}

.investor_data {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: auto;

  height: 31px;

  /* primary/0 */

  background: #ffffff;
  /* primary/100 */

  border: 1px solid #e4e5e7;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.investor_list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 20px;
  padding-top: 20px;
}

div.title {
  text-align: left;
  padding-top: 56px;
  padding-left: 5px;
  font-size: 23px;
  font-weight: bold;
}
.form-container {
  width: 588px;
  padding-top: 20px;
}
.div-label {
  text-align: left;
  font-size: 14px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 145%;
}
.form-control {
  border: 1px solid #e4e5e7;
}
.form-control {
  margin-top: 4px;
  margin-bottom: 0px;
}
.form-grouped {
  margin-top: 0px;
}

.form_errors {
  color: red;
}
.check-product-name {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  margin-top: 8px;
}
.light-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
  text-align: left;
}
.chip-btn-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 8px;
}
.button-container {
  /*display: flex;*/
  justify-content: flex-end;
  margin: 48px 0px;
  float: left;
}
.text-box-hint {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 8px;
}
.medium-combo-box .v-text-field {
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 130%;
}

.textbox-wrapper {
  display: flex;
}
.textbox-wrapper div {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  /* background-color: #fff; */
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0;
  width: 42px;
  background: black;
  color: white;
  height: 51px;
}
.light-text-label {
  color: #979da5;
}
.display-inline {
  display: inline-block;
  /* padding-top: 50px; */
}
.list-wrapper {
  display: flex;
  margin-top: 5px;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
}
.label-description {
  color: #979da5;
}
.brief_counter {
  margin-bottom: 28px;
}
@media (max-width: 768px) {
  .form-container {
    width: 100%;
  }
  .button-container {
    float: none;
  }
  .grupa-blue-btn {
    width: 100%;
  }
  .list-wrapper {
    width: 100%;
  }
  div.title {
    padding-top: 32px;
  }
  .chip-btn-container {
    width: 100%;
  }
  .form-grouped {
    margin-top: 32px;
  }
}
</style>
