var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-card-2" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "row investor_list" },
      _vm._l(
        JSON.parse(_vm.isEditInvestorField ? _vm.backed_by : _vm.formBacked_by),
        function (data, i) {
          return _c("div", { key: i, staticClass: "investor_data" }, [
            _vm._v("\n      " + _vm._s(data) + "\n    "),
          ])
        }
      ),
      0
    ),
    _c("div", { staticClass: "start_up_line" }),
    _c(
      "div",
      {
        staticClass: "row",
        staticStyle: { "padding-top": "30px", "padding-bottom": "20px" },
      },
      [
        _c(
          "div",
          {
            staticClass: "col-sm-6",
            staticStyle: {
              "text-align": "left",
              "margin-top": "-10px",
              "padding-top": "10px",
            },
          },
          [
            _c("span", { staticClass: "start_up_label" }, [
              _vm._v("startup stage"),
            ]),
            _c("div", { staticClass: "start_up_data" }, [
              _vm._v(_vm._s(_vm.stage.replace("Mood", "Round"))),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-sm-6",
            staticStyle: {
              "text-align": "left",
              "border-left": "1px solid #e4e5e7",
              "margin-top": "-10px",
              "margin-left": "-20px",
              "padding-left": "20px",
              "padding-top": "10px",
            },
          },
          [
            _c("span", { staticClass: "start_up_label" }, [
              _vm._v("current valuation"),
            ]),
            _c("div", { staticClass: "start_up_data" }, [
              _vm._v("$" + _vm._s(_vm.amountFormat(this.price))),
            ]),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "editInvestorInfo",
          tabindex: "-1",
          "aria-labelledby": "editInvestorInfoLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "550px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _c("form", { on: { submit: _vm.addInvestorButton } }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    staticStyle: { padding: "20px", "padding-top": "50px" },
                  },
                  [
                    _c("span", { staticClass: "modal_label" }, [
                      _vm._v(
                        "Add your startup’s investors, stage and current\n              valuation."
                      ),
                    ]),
                    _c("br"),
                    _c("br"),
                    _c("div", { staticClass: "form-grouped" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        { staticClass: "grouped-form medium-combo-box" },
                        [
                          _c("input", {
                            staticClass: "form-control",
                            staticStyle: { margin: "0px" },
                            attrs: {
                              type: "text",
                              name: "backed_by",
                              id: "backed_by",
                              placeholder:
                                "Investor name(s), e.g. Founders Fund, Khosla Venuters, etc..",
                              required: "",
                            },
                            domProps: {
                              value: "" + String(JSON.parse(_vm.backed_by)),
                            },
                            on: { keyup: _vm.updateData },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "form-grouped" }, [
                        _c("div", { staticClass: "div-label" }, [
                          _vm._v("Startup stage"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "light-label text-box-hint" },
                          [
                            _vm._v(
                              "\n                  What stage is your startup?\n                "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "chip-btn-container" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.v_model_stage,
                                  expression: "v_model_stage",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { name: "stage", id: "stage" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.v_model_stage = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.updateData(
                                      $event,
                                      $event.target.selectedIndex
                                    )
                                  },
                                ],
                              },
                            },
                            _vm._l(_vm.startupStages, function (item, index) {
                              return _c(
                                "option",
                                { key: index, domProps: { value: item.name } },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(item.name) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-grouped" }, [
                        _c("div", { staticClass: "div-label" }, [
                          _vm._v("Current valuation ($)"),
                        ]),
                        _c("div", { staticClass: "textbox-wrapper" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.price,
                                expression: "price",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "price",
                              autocomplete: "off",
                              placeholder: "What’s your current valuation",
                              id: "price",
                              maxlength: "11",
                              type: "text",
                            },
                            domProps: { value: _vm.price },
                            on: {
                              keyup: _vm.updateData,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.price = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "modal-footer" }, [
                  _vm.isUpdateInvestor
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            right: "0",
                            "margin-top": "0%",
                            "text-align": "center",
                          },
                          attrs: { id: "loaderCal" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 30,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s("updating investor...") +
                              "\n            "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isUpdateInvestor
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-light lightBorder p-3",
                          attrs: { type: "button", "data-bs-dismiss": "modal" },
                        },
                        [_vm._v("\n              Cancel\n            ")]
                      )
                    : _vm._e(),
                  !_vm.isUpdateInvestor
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary p-3",
                          attrs: { type: "submit" },
                        },
                        [_vm._v("\n              Update\n            ")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "errorModalInvestor",
          tabindex: "-1",
          "aria-labelledby": "errorModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(3),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("br"),
                  _c("img", {
                    staticStyle: { "margin-top": "30px", width: "150px" },
                    attrs: { src: "/img/cancel.png" },
                  }),
                  _c("br"),
                  _c("br"),
                  _c("p", { staticStyle: { "margin-top": "30px" } }, [
                    _vm._v(
                      "\n            Error detected while updating investor info\n          "
                    ),
                  ]),
                  _c("code", [_vm._v(_vm._s(this.investorInfoUpdateError))]),
                  _c("br"),
                  _c("br"),
                ]
              ),
              _vm._m(4),
            ]),
          ]
        ),
      ]
    ),
    _vm._m(5),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6", staticStyle: { "text-align": "left" } },
        [_c("span", { staticClass: "start_up_label" }, [_vm._v("investors")])]
      ),
      _c(
        "div",
        {
          staticClass: "col-6",
          staticStyle: { "text-align": "right" },
          attrs: {
            "data-bs-toggle": "modal",
            "data-bs-target": "#editInvestorInfo",
          },
        },
        [_c("span", { staticClass: "start_up_edit" }, [_vm._v("Edit")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Edit Investors Info")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "div-label" }, [
      _vm._v("\n                Investor name(s)\n\n                "),
      _c("div", { staticClass: "text-box-hint" }, [
        _vm._v(
          "\n                  Separate VC names with a comma.\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("ERROR DETECTED")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light lightBorder",
          attrs: { type: "button", "data-bs-dismiss": "modal" },
        },
        [_vm._v("\n            Close\n          ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "successChangeRequestModalInvestor",
          tabindex: "-1",
          "aria-labelledby": "successChangeRequestModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Investor Info Updated"),
                ]),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: {
                    type: "button",
                    "data-bs-dismiss": "modal",
                    "aria-label": "Close",
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("br"),
                  _c("img", {
                    staticStyle: { "margin-top": "30px", width: "150px" },
                    attrs: { src: "/img/marked.svg" },
                  }),
                  _c("br"),
                  _c("br"),
                  _c("p", { staticStyle: { "margin-top": "30px" } }, [
                    _vm._v(
                      "\n            Investor info record updated successfully\n          "
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                ]
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light lightBorder",
                    attrs: { type: "button", "data-bs-dismiss": "modal" },
                  },
                  [_vm._v("\n            Close\n          ")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }