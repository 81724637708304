var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "nav-wrapper" }, [
      _c("div", { staticClass: "stage-title ", attrs: { data: "tab1" } }, [
        _vm._v("Personal Profile"),
      ]),
      _c("div", { staticClass: "stage-title ", attrs: { data: "tab2" } }, [
        _vm._v("Startup Profile"),
      ]),
      _c(
        "div",
        { staticClass: "stage-title active", attrs: { data: "tab3" } },
        [_vm._v(_vm._s(_vm.project_name || "No Project"))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }