<template>
  <div class="profile-card-2" style="margin-top: 10px">
    <div class="row">
      <div class="col-6" style="text-align: left">
        <span class="start_up_label">preferred compensation</span>
      </div>
      <div
        class="col-6"
        style="text-align: right"
        data-bs-toggle="modal"
        data-bs-target="#editPref"
      >
        <span class="start_up_edit">Edit</span>
      </div>
    </div>

    <div class="row" style="padding-top: 0px">
      <div
        class="col-sm-12"
        style="text-align: left; margin-top: -10px; padding-top: 10px"
      >
        <div class="row investor_list">
          <div class="investor_data">
            <img src="/img/cash1.svg" alt="" />
            {{ compensation ? compensationMethod(compensation) : "" }}
          </div>

          <!--  <div class="investor_data">
            <img src="/img/cash1.svg" alt="" /> Cash only
          </div>
          <div class="investor_data">
            <img src="/img/cash1.svg" alt="" /> A mix of cash & equity
          </div> -->
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="editPref"
      tabindex="-1"
      aria-labelledby="editPrefLabel"
      aria-hidden="true"
      style=""
    >
      <div class="modal-dialog" style="width: 550px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Preferred Compensation Method</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit="updatePreference">
            <div class="modal-body" style="padding: 30px; padding-top: 50px">
              <span class="modal_label"
                >You can pay an Expert in any of these 3 ways. Select your
                preferred methods</span
              ><br /><br />

              <v-radio-group v-model="formPref">
                <div
                  style="padding: 10px; padding-bottom: 16px; display: flex"
                  class="form-grouped"
                  :key="item.label"
                  v-for="(item, index) in Pref"
                >
                  <v-radio :key="item.label" :value="item.name">
                    <template v-slot:label>
                      <div class="checkBoxLabel">
                        {{ item.name }}
                      </div>

                      <div
                        class="text-box-hint checkBoxLabel2"
                        :style="`margin-left: ${(index = 1
                          ? '60px;'
                          : '50px;')}`"
                      >
                        {{ item.label }}
                      </div>
                    </template>
                  </v-radio>

                  <br />
                </div>
              </v-radio-group>

              <div class="form-grouped">
                *equity components are subject to a 3 months cliff period and 18
                months vesting period
              </div>
            </div>

            <div class="modal-footer">
              <div
                v-if="isUpdatePref"
                style="right: 0%; right: 0; margin-top: 0%; text-align: center"
                id="loaderCal"
              >
                <v-progress-circular
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                {{ "updating compensation..." }}
              </div>

              <button
                v-if="!isUpdatePref"
                type="button"
                class="btn btn-light lightBorder p-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                v-if="!isUpdatePref"
                type="submit"
                class="btn btn-primary p-3"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="errorModalPrefe"
      tabindex="-1"
      aria-labelledby="errorModalPrefeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ERROR DETECTED</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/cancel.png"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Error detected while updating compensation
            </p>
            <code>{{ this.prefInfoUpdateError }}</code>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light lightBorder"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="successChangeRequestModalPrefe"
      tabindex="-1"
      aria-labelledby="successChangeRequestModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Compensation Updated</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/marked.svg"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Compensation record updated successfully
            </p>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-light lightBorder"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//

import { mapActions } from "vuex";

import { updatePreference } from "@/api/client";
export default {
  props: ["compensation", "project_id"],
  data: () => ({
    formPref: "",
    isUpdatePref: "",
    prefInfoUpdateError: "",
    Pref: [
      {
        name: "Cash Only",
        label: "Starts at $12,500 per month",
      },
      {
        name: "A mix of cash & equity",
        label: "Starts at $7,500 per month & 0.25% equity*",
      },
      {
        name: "Equity only",
        label: "Starts at 0.5% equity*",
      },
    ],
  }),
  mounted() {
    // alert(this.compensation)
    this.formPref = this.compensationMethod(this.compensation);
    //alert(this.formPref )
  },
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    async updatePreference(event) {
      event.preventDefault();

      const payload = {
        //  startup_id: this.startup_id,
        //client_id: this.client_id,
        project_id: this.project_id,
        compensation_id: this.finalFIlter(this.formPref),
        formPref: this.formPref,
      };

      this.isUpdatePref = true;

      await updatePreference(payload)
        .then(async (response) => {
          this.isUpdatePref = false;
          $("#editPref").modal("hide");

          const { project } = response.data.data;

          if (response.data.status == 1) {
            this.updateProject(project);

            $("#successChangeRequestModalPrefe").modal("show");

            //
          } else {
            this.prefInfoUpdateError = response.data.errors;
            $("#errorModalPrefe").modal("show");
          }
        })
        .catch((error) => {
          $("#editPref").modal("hide");
          this.isUpdatePref = false;
          if (error.hasOwnProperty("message")) {
            this.prefInfoUpdateError = error.message;
          } else {
            this.prefInfoUpdateError = error.data.errors;
          }

          $("#errorModalPrefe").modal("show");
        });

      // console.log(payload);
    },
    compensationMethod(b) {
      let a = parseInt(b);
      if (a == null) {
        return "";
      }
      switch (a) {
        case 0:
          return "Cash Only";
        case 1:
          return "A mix of cash & equity";
        case 2:
          return "Equity only";
        case 3:
          return "I’m not sure yet";

        default:
          return "None";
      }
    },

    compensationIndexTracker(a) {
      if (a == null) {
        return "";
      }
      switch (a) {
        case "Cash Only":
          return 1;
        case "A mix of cash & equity":
          return 2;
        case "Equity only":
          return 3;

        default:
          return 4;
      }
    },

    finalFIlter(a) {
      if (a == null) {
        return "";
      }
      switch (a) {
        case "Cash Only":
          return 0;
        case "A mix of cash & equity":
          return 1;
        case "Equity only":
          return 2;

        default:
          return 3;
      }
    },
  },
};
</script>

<style scoped>
.checkBoxLabel {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-left: 10px;
  margin-top: 1px;
  /* or 20px */

  /* primary/700 (text) */

  color: #1b1e22;
}
.checkBoxLabel2 {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */
  margin-left: 30px;

  /* primary/300 */

  color: #979da5;
}
.checkBox {
  width: 20px;
  height: 20px;

  /* primary/0 */

  background: #ffffff;
  /* primary/100 */

  border: 1px solid #e4e5e7;
  border-radius: 4px;
}
.profile-card-2 {
  text-align: left;
  background: #ffffff;

  border-bottom: 1px solid #eee;
  padding: 20px;
}
.investor_data {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: auto;

  height: 31px;

  /* primary/0 */

  background: #ffffff;
  /* primary/100 */

  border: 1px solid #e4e5e7;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.investor_list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 20px;
  padding-top: 20px;
}

.start_up_data {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-top: 30px;
  padding-bottom: 20px;

  color: #1b1e22;
}
.start_up_edit {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;

  text-align: right;
  cursor: pointer;
  color: #0781f2;
}
.start_up_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  text-transform: uppercase;

  color: #979da5;
}
</style>
