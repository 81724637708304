var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-cover", staticStyle: { display: "block" } },
    [
      _c(
        "div",
        {
          staticClass: "row profile-card",
          staticStyle: { "padding-top": "20" },
        },
        [
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _c("founderProfileCard", {
            attrs: {
              founderData: _vm.founderData,
              removeFounder: _vm.removeFounder,
              avatar: _vm.rand(this.avatar),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "addFounder",
            tabindex: "-1",
            "aria-labelledby": "addFounderLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(3),
              _c("form", { on: { submit: _vm.addFounderButton } }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    staticStyle: { padding: "20px", "padding-top": "50px" },
                  },
                  [
                    _c("span", { staticClass: "modal_label" }, [
                      _vm._v(
                        "Add the founder’s linkedin profile and specify their role in\n              the company."
                      ),
                    ]),
                    _c("br"),
                    _c("br"),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {}, [_vm._v("Fullname")]),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "name",
                          id: "name",
                          placeholder: "Fullname",
                          required: "",
                        },
                        on: { keyup: _vm.updateData },
                      }),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", {}, [_vm._v("LinkedIn url")]),
                      _c("input", {
                        staticClass: "form-control",
                        staticStyle: { margin: "0px" },
                        attrs: {
                          type: "text",
                          name: "url",
                          id: "url",
                          placeholder: "LinkedIn Profile link",
                          required: "",
                        },
                        on: { keyup: _vm.updateData },
                      }),
                      _c("small", { staticClass: "form-text text-muted" }, [
                        _vm._v("kindly provide a valid LinkedIn Url."),
                      ]),
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "modal_label" }, [
                      _vm._v("Compay role"),
                    ]),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "role",
                        id: "role",
                        placeholder: "e.g. CEO",
                        required: "",
                      },
                      on: { keyup: _vm.updateData },
                    }),
                  ]
                ),
                _c("div", { staticClass: "modal-footer" }, [
                  _vm.isAddingFounder
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            right: "0",
                            "margin-top": "0%",
                            "text-align": "center",
                          },
                          attrs: { id: "loaderCal" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 30,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s("adding founder...") +
                              "\n            "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isAddingFounder
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-light p-3 lightBorder",
                          attrs: { type: "button", "data-bs-dismiss": "modal" },
                        },
                        [_vm._v("\n              Cancel\n            ")]
                      )
                    : _vm._e(),
                  !_vm.isAddingFounder
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary confirmTeamProceed p-3",
                          attrs: { type: "submit" },
                        },
                        [_vm._v("\n              Update\n            ")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "errorModal",
            tabindex: "-1",
            "aria-labelledby": "errorModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(4),
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _c("br"),
                    _c("img", {
                      staticStyle: { "margin-top": "30px", width: "150px" },
                      attrs: { src: "/img/cancel.png" },
                    }),
                    _c("br"),
                    _c("br"),
                    _c("p", { staticStyle: { "margin-top": "30px" } }, [
                      _vm._v(
                        "\n            Error detected while\n            " +
                          _vm._s(
                            _vm.action === "add" ? "creating" : "removing"
                          ) +
                          " founder\n          "
                      ),
                    ]),
                    _c("code", [
                      _vm._v(
                        _vm._s(
                          this.founderCreateError || this.founderRemoveError
                        )
                      ),
                    ]),
                    _c("br"),
                    _c("br"),
                  ]
                ),
                _vm._m(5),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "successChangeRequestModal",
            tabindex: "-1",
            "aria-labelledby": "successChangeRequestModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(
                      "\n            Founder " +
                        _vm._s(_vm.action === "add" ? "Added" : "Removed") +
                        "\n          "
                    ),
                  ]),
                  _c("button", {
                    staticClass: "btn-close",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "modal",
                      "aria-label": "Close",
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _c("br"),
                    _c("img", {
                      staticStyle: { "margin-top": "30px", width: "150px" },
                      attrs: { src: "/img/marked.svg" },
                    }),
                    _c("br"),
                    _c("br"),
                    _c("p", { staticStyle: { "margin-top": "30px" } }, [
                      _vm._v(
                        "\n            Founders record\n            " +
                          _vm._s(_vm.action === "add" ? "created" : "removed") +
                          " successfully\n          "
                      ),
                    ]),
                    _c("br"),
                    _c("br"),
                  ]
                ),
                _vm._m(6),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "removeFounderModal",
            tabindex: "-1",
            "aria-labelledby": "removeFounderModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v("\n            Delete Founder\n            "),
                    _c("span", { staticClass: "badge badge-primary" }, [
                      _c("h6", { staticStyle: { "margin-bottom": "0px" } }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.selectedFounder.name) +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("button", {
                    staticClass: "btn-close",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "modal",
                      "aria-label": "Close",
                    },
                  }),
                ]),
                _vm._m(7),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light lightBorder",
                      attrs: { type: "button", "data-bs-dismiss": "modal" },
                    },
                    [_vm._v("\n            Close\n          ")]
                  ),
                  _vm.isRemovingFounder
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            right: "0",
                            "margin-top": "0%",
                            "text-align": "center",
                          },
                          attrs: { id: "loaderCal" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 30,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s("removing founder...") +
                              "\n          "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isRemovingFounder
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: { click: _vm.startFounderRemove },
                        },
                        [_vm._v("\n            Remove\n          ")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-6", staticStyle: { border: "none" } },
      [_c("span", { staticClass: "title" }, [_vm._v("FOUNDERS")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-6",
        staticStyle: { "text-align": "right" },
        attrs: { "data-bs-toggle": "modal", "data-bs-target": "#addFounder" },
      },
      [_c("span", { staticClass: "start_up_edit" }, [_vm._v("Add Founder")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bold-sub-title" }, [
      _c(
        "div",
        {
          staticStyle: {
            width: "350px",
            "padding-top": "20px",
            "margin-bottom": "0px",
          },
        },
        [
          _vm._v(
            "\n        Add the linkedIn profile of the founder(s), show the folks behind the\n        startup.\n      "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Add Founder")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("ERROR DETECTED")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light lightBorder",
          attrs: { type: "button", "data-bs-dismiss": "modal" },
        },
        [_vm._v("\n            Close\n          ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light lightBorder",
          attrs: { type: "button", "data-bs-dismiss": "modal" },
        },
        [_vm._v("\n            Close\n          ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal-body", staticStyle: { "text-align": "center" } },
      [
        _c("br"),
        _c("p", { staticStyle: { "margin-top": "30px" } }, [
          _vm._v(
            "\n            Are you sure you want to delete this founders record ?\n          "
          ),
        ]),
        _c("br"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }