<template>
  <div class="box profile-card-2">
    <div class="row">
      <div class="col-6" style="text-align: left">
        <span class="start_up_label">STARTUP NAME</span>
      </div>
      <div
        class="col-6"
        style="text-align: right"
        data-bs-toggle="modal"
        data-bs-target="#editStartUpInfo2"
      >
        <span class="start_up_edit">Edit</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="start_up_data">{{ startup.name }}</div>
      </div>
    </div>

    <div class="start_up_line"></div>

    <div class="row" style="padding-top: 30px; padding-bottom: 20px">
      <div
        class="col-sm-6"
        style="text-align: left; margin-top: -10px; padding-top: 10px"
      >
        <span class="start_up_label">STARTUP website</span>

        <div class="start_up_data">
          {{
            startup.website == null
              ? ""
              : startup.website.length > 30
              ? startup.website.slice(0, 30) + "..."
              : startup.website
          }}
        </div>
      </div>
      <div
        class="col-sm-6"
        style="
          text-align: left;
          border-left: 1px solid #e4e5e7;
          margin-top: -10px;
          margin-left: -20px;
          padding-left: 20px;
          padding-top: 10px;
        "
      >
        <span class="start_up_label">startup location</span>

        <div class="start_up_data">{{ startup.location }}</div>
      </div>
    </div>

    <div class="start_up_line"></div>
    <div class="row" style="padding-top: 30px; min-height: 143px">
      <div
        class="col-sm-12"
        style="text-align: left; margin-top: -10px; padding-top: 10px"
      >
        <span class="start_up_label">startup BIO</span>

        <div class="start_up_data">
          {{ startup.description }}
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="editStartUpInfo2"
      tabindex="-1"
      aria-labelledby="editStartUpInfoLabel"
      aria-hidden="true"
      style=""
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Startup Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit="editStartUpInfo2">
            <div class="modal-body" style="padding: 20px; padding-top: 50px">
              <span class="modal_label"
                >Edit startup name, website, location and bio.</span
              ><br /><br />
              <span class="modal_label">Startup name</span>
              <input
                type="text"
                name="name"
                id="name"
                :value="`${startup.name}`"
                v-on:keyup="updateData"
                placeholder="name"
                required
                class="form-control"
              />

              <span class="modal_label">Startup website</span>
              <input
                type="text"
                name="website"
                id="website"
                :value="`${startup.website == null ? '' : startup.website}`"
                v-on:keyup="updateData"
                placeholder="http://"
                required
                class="form-control"
              />

              <span class="modal_label">Startup location</span>
              <input
                type="text"
                name="location"
                id="location"
                :value="`${startup.location == null ? '' : startup.location}`"
                v-on:keyup="updateData"
                placeholder="location"
                required
                class="form-control"
              />

              <span class="modal_label">Startup bio</span>
              <textarea
                name="bio"
                id="bio"
                :value="`${startup.description}`"
                v-on:keyup="updateData"
                placeholder="Biodata"
                required
                class="form-control"
                style="height: 70px"
              />
            </div>

            <div class="modal-footer">
              <div
                v-if="isUpdateStartUp"
                style="right: 0%; right: 0; margin-top: 0%; text-align: center"
                id="loaderCal"
              >
                <v-progress-circular
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                {{ "updating startup..." }}
              </div>

              <button
                v-if="!isUpdateStartUp"
                type="button"
                class="btn btn-light lightBorder p-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                v-if="!isUpdateStartUp"
                type="submit"
                class="btn btn-primary p-3"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="errorModalStartUp"
      tabindex="-1"
      aria-labelledby="errorModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ERROR DETECTED</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/cancel.png"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Error detected while updating startup info
            </p>
            <code>{{ this.starupInfoUpdateError }}</code>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light lightBorder"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="successChangeRequestModalStartUp"
      tabindex="-1"
      aria-labelledby="successChangeRequestModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Startup Info Updated</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/marked.svg"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              Starup info record updated successfully
            </p>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-light lightBorder"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//
import { updateStartUp } from "@/api/client";
import { mapActions } from "vuex";

export default {
  props: ["startup", "user_type", "project_id"],
  data: () => ({
    isUpdateStartUp: false,
    startUpName: "",
    startupId: "",
    startUpWeb: "",
    startUpLocation: "",
    startUpBio: "",
    starupInfoUpdateError: "",
  }),
  components: {},
  mounted() {
    //console.log(this.startup);

    this.startUpName = this.startup.name;
    this.startUpId = this.startup.id;
    this.startUpWeb = this.startup.website;
    this.startUpLocation = this.startup.location;
    this.startUpBio = this.startup.description;
  },
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    async editStartUpInfo2(event) {
      event.preventDefault();

      const payload = {
        id: this.startup.id,
        location: this.startUpLocation,
        website: this.startUpWeb,
        description: this.startUpBio,
        name: this.startUpName,
        project_id: this.project_id,
      };

      //

      this.isUpdateStartUp = true;
      await updateStartUp(payload)
        .then(async (response) => {
          $("#editStartUpInfo2").modal("hide");
          this.isUpdateStartUp = false;

          const { project } = response.data.data;

          if (response.data.status == 1) {
            this.updateProject(project);

            $("#successChangeRequestModalStartUp").modal("show");
            // window.location.reload();

            //
          } else {
            this.starupInfoUpdateError = response.data.errors;
            $("#errorModalStartUp").modal("show");
          }
        })
        .catch((error) => {
          $("#editStartUpInfo2").modal("hide");
          this.isUpdateStartUp = false;
          if (error.hasOwnProperty("message")) {
            this.starupInfoUpdateError = error.message;
          } else {
            this.starupInfoUpdateError = error.data.errors;
          }

          $("#errorModalStartUp").modal("show");
        });
    },
    updateData(evt) {
      let name = evt.target.name;
      let value = evt.target.value;
      if (name === "name") {
        this.startUpName = value;
      } else if (name === "website") {
        this.startUpWeb = value;
      } else if (name === "location") {
        this.startUpLocation = value;
      } else if (name === "bio") {
        this.startUpBio = value;
      }
    },
  },
};
</script>

<style scoped>
.lightBorder {
  border: 1px solid #eee;
}
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* primary/700 (text) */

  color: #1b1e22;
  padding-bottom: 10px;
}

.profile-card-2 {
  text-align: left;
  background: #ffffff;

  border-bottom: 1px solid #eee;
  padding: 20px;
}
.start_up_line {
  border: 0.5px solid #e4e5e7;
}

.start_up_data {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-top: 30px;
  padding-bottom: 20px;

  color: #1b1e22;
}
.start_up_edit {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;

  text-align: right;
  cursor: pointer;
  color: #0781f2;
}
.start_up_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  text-transform: uppercase;

  color: #979da5;
}
</style>
