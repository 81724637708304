var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-card-2", staticStyle: { "margin-top": "10px" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "row", staticStyle: { "padding-top": "0px" } }, [
        _c(
          "div",
          {
            staticClass: "col-sm-12",
            staticStyle: {
              "text-align": "left",
              "margin-top": "-10px",
              "padding-top": "10px",
            },
          },
          [
            _c("div", { staticClass: "row investor_list" }, [
              _c("div", { staticClass: "investor_data" }, [
                _c("img", { attrs: { src: "/img/cash1.svg", alt: "" } }),
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.compensation
                        ? _vm.compensationMethod(_vm.compensation)
                        : ""
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "editPref",
            tabindex: "-1",
            "aria-labelledby": "editPrefLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", staticStyle: { width: "550px" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(1),
                _c("form", { on: { submit: _vm.updatePreference } }, [
                  _c(
                    "div",
                    {
                      staticClass: "modal-body",
                      staticStyle: { padding: "30px", "padding-top": "50px" },
                    },
                    [
                      _c("span", { staticClass: "modal_label" }, [
                        _vm._v(
                          "You can pay an Expert in any of these 3 ways. Select your\n              preferred methods"
                        ),
                      ]),
                      _c("br"),
                      _c("br"),
                      _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.formPref,
                            callback: function ($$v) {
                              _vm.formPref = $$v
                            },
                            expression: "formPref",
                          },
                        },
                        _vm._l(_vm.Pref, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.label,
                              staticClass: "form-grouped",
                              staticStyle: {
                                padding: "10px",
                                "padding-bottom": "16px",
                                display: "flex",
                              },
                            },
                            [
                              _c("v-radio", {
                                key: item.label,
                                attrs: { value: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "checkBoxLabel" },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.name) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-box-hint checkBoxLabel2",
                                              style:
                                                "margin-left: " +
                                                (index = 1 ? "60px;" : "50px;"),
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.label) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("br"),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "form-grouped" }, [
                        _vm._v(
                          "\n              *equity components are subject to a 3 months cliff period and 18\n              months vesting period\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "modal-footer" }, [
                    _vm.isUpdatePref
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              right: "0",
                              "margin-top": "0%",
                              "text-align": "center",
                            },
                            attrs: { id: "loaderCal" },
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 30,
                                color: "primary",
                                indeterminate: "",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s("updating compensation...") +
                                "\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isUpdatePref
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-light lightBorder p-3",
                            attrs: {
                              type: "button",
                              "data-bs-dismiss": "modal",
                            },
                          },
                          [_vm._v("\n              Cancel\n            ")]
                        )
                      : _vm._e(),
                    !_vm.isUpdatePref
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary p-3",
                            attrs: { type: "submit" },
                          },
                          [_vm._v("\n              Update\n            ")]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "errorModalPrefe",
            tabindex: "-1",
            "aria-labelledby": "errorModalPrefeLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(2),
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _c("br"),
                    _c("img", {
                      staticStyle: { "margin-top": "30px", width: "150px" },
                      attrs: { src: "/img/cancel.png" },
                    }),
                    _c("br"),
                    _c("br"),
                    _c("p", { staticStyle: { "margin-top": "30px" } }, [
                      _vm._v(
                        "\n            Error detected while updating compensation\n          "
                      ),
                    ]),
                    _c("code", [_vm._v(_vm._s(this.prefInfoUpdateError))]),
                    _c("br"),
                    _c("br"),
                  ]
                ),
                _vm._m(3),
              ]),
            ]
          ),
        ]
      ),
      _vm._m(4),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6", staticStyle: { "text-align": "left" } },
        [
          _c("span", { staticClass: "start_up_label" }, [
            _vm._v("preferred compensation"),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "col-6",
          staticStyle: { "text-align": "right" },
          attrs: { "data-bs-toggle": "modal", "data-bs-target": "#editPref" },
        },
        [_c("span", { staticClass: "start_up_edit" }, [_vm._v("Edit")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v("Edit Preferred Compensation Method"),
      ]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("ERROR DETECTED")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light lightBorder",
          attrs: { type: "button", "data-bs-dismiss": "modal" },
        },
        [_vm._v("\n            Close\n          ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "successChangeRequestModalPrefe",
          tabindex: "-1",
          "aria-labelledby": "successChangeRequestModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Compensation Updated"),
                ]),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: {
                    type: "button",
                    "data-bs-dismiss": "modal",
                    "aria-label": "Close",
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("br"),
                  _c("img", {
                    staticStyle: { "margin-top": "30px", width: "150px" },
                    attrs: { src: "/img/marked.svg" },
                  }),
                  _c("br"),
                  _c("br"),
                  _c("p", { staticStyle: { "margin-top": "30px" } }, [
                    _vm._v(
                      "\n            Compensation record updated successfully\n          "
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                ]
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light lightBorder",
                    attrs: { type: "button", "data-bs-dismiss": "modal" },
                  },
                  [_vm._v("\n            Close\n          ")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }